/* You can add global styles to this file, and also import other style files */
@import "chat";
@import "./variables.scss";

.header-logo > img {
  width: 120px;
  margin: 0 30px;
}

.dialog-close {
  position: relative;
  top: 0;
  right: 0;
}
.full-screen-dialog-content-container {
  height: 80vh;
  width: 80vw;
}
.invisible-panel-dialog .mat-dialog-container {
  overflow: hidden !important;
}

.scheduler-panel-class .mat-dialog-container {
  overflow: visible !important;
}

.invisible-panel-dialog {
  max-width: 100vw !important;

  .mat-dialog-container {
    background: transparent !important;
    box-shadow: none !important;
  }
}

.overflow-bottom-sheet {
  overflow: visible !important;
  max-width: 900px !important;
}
.widget-card {
  width: 350px;
  height: 350px;
  mat-card-header {
    margin: -16px -16px 0 -16px !important;
    padding: 16px 8px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    mat-card-title {
      font-size: 20px;
    }
  }
  mat-card-content {
    height: 260px;
  }
}
.kpi-widget {
  width: 83vw;
  height: 500px;
}
.dialog-card {
  padding: 0px !important;
  mat-card-content {
    padding: 16px;
  }
  mat-card-actions {
    padding: 16px !important;
  }
}
mat-card-actions.scroll-under-actions {
  margin-left: -16px;
  margin-right: -16px;
  padding: 10px;
  box-shadow: 0px -3px 5px 0px darkgrey;
}
mat-card-content.scrolling-content {
  margin-bottom: 1px;
}
.error-card-header {
  background: #f34335;
  color: white;
}
.action-card-header {
  background: #3949ab;
  color: white;
}
.success-card-header {
  background: #009688;
  color: white;
}
.dialog-close-button {
  margin: 17px 0px 0px 26px !important;
  z-index: 99999;
  position: absolute !important;
}
.floating-action-button {
  position: fixed !important;
  top: 80px;
  right: 10px;
  z-index: 999;
}
img.tutorial-step-image.ng-star-inserted {
  height: 200px;
}

/**
Datatable styles
 */
// .mat-row:hover .mat-cell {
//   background-color: #909be21f;
// }
.mat-table-cell-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cell-warn {
  background: #ff00003d;
}
.cell-attn {
  background: #ffee003d;
}
.scrolling-table-container {
  height: 450px;
  padding: 2px;
  overflow: auto;
  max-width: 100% !important;
  mat-table {
    width: fit-content !important;
  }
}
.report-table {
  min-width: 100%;

  .mat-header-row {
    font-weight: bold;
  }
  .mat-header-cell:first-of-type {
    padding-left: 5px !important;
  }
  .mat-header-cell {
    font-weight: bold;
    height: 80px;
    border-right: solid 0.5px white;
  }

  .mat-cell,
  .mat-footer-cell {
    border-right: 1px solid #f4f4f4;
    width: fit-content;
    white-space: nowrap;
    padding: 3px;
  }
  .mat-sort-header-container {
    justify-content: center !important;
    padding-left: 18px !important; /* Allow for sort icon*/
  }

  .mat-cell {
    text-align: center;
  }
  .mat-footer-cell {
    text-align: center;
    justify-content: center;
  }
  .left-align-cell.mat-cell {
    text-align: start;
    justify-content: start;
  }

  .left-align-header > .mat-sort-header-container {
    justify-content: flex-start !important;
    padding-left: 0px !important;
  }
  width: fit-content !important;
  .content-cell {
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  .wrap-content-cell {
    div {
      white-space: pre-line;
      text-align: start;
    }
  }

  mat-cell:not(.mat-column-expandedDetail) {
    border-right: 1px solid #f4f4f4;
    text-align: center;
    justify-content: center;
  }

  mat-header-cell {
    border-right: 1px solid #f4f4f4;
    text-align: center;
    justify-content: center;
  }

  .variance {
    font-weight: bolder;
  }

  .variance.difference {
    color: red;
  }
}

mat-cell {
  &:not(.mat-column-expandedDetail) {
    padding: 4px;
  }
}

mat-row.example-detail-row {
  min-height: 0;
}

mat-row.example-element-row:not(.example-expanded-row):hover {
  background: #c5c5c5;
}

mat-row.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.example-element-row mat-cell {
  border-bottom-width: 0;
}

.example-element-detail {
  width: 100%;
}

.expanded-cell-data {
  padding: 5px 5px;
}

.delivery-partner-rate-sub-table {
  thead {
    color: rgba(0, 0, 0, 0.6);
    border-bottom: whitesmoke 1px solid;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    border-bottom: 1px solid #0000001f;
  }

  td,
  th {
    padding: 15px 10px;
  }

  .table-rate-cell {
    max-width: 40px;
  }
}

span.wdr-ui-element.wdr-ui.wdr-ui-label.wdr-credits {
  display: none !important;
}

form:not(.search-form) {
  padding-top: 20px;
}

.client-selector-card {
  cursor: pointer;
  height: 200px;
  width: 300px;
  padding: 14px;
}

.client-selector-card:hover {
  background: #dcdcdc !important;
}

.departmentsNavLink {
  border-top: 0.5px solid #cccccc;
  padding-left: 84px !important;
}

.projectsNavLink {
  border-top: 0.5px solid #cccccc;
  padding-left: 104px !important;
}

.rate-input {
  width: 5px;
}

.yarrow {
  z-index: 1000;
}

.error-text {
  color: $negative-red !important;
}

/***
Notification badges
 */

.notification-label {
  position: absolute;
  top: 0;
  left: 73%;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: white;
  text-align: center;
}
.notification-label.warn {
  background-color: #f44336;
  border: 2px solid #f44336;
}

.notification-label.accent {
  background-color: #009688;
  border: 2px solid #009688;
}
.notification-label.extra-left {
  left: 85%;
}
/***
Tab Group
 */
.scroll-tab-button {
  width: 50px;
  min-width: 50px;
  z-index: 2;
}
.tab-group-container {
  overflow-y: hidden;
}
.scroll-tab-button.right {
  cursor: pointer;
  box-shadow: -4px -1px 4px -1px rgba(0, 0, 0, 0.2),
    -2px -1px 5px 0px rgba(0, 0, 0, 0.14),
    -2px -1px 10px 0px rgba(0, 0, 0, 0.12);
}
.scroll-tab-button.left {
  cursor: pointer;
  box-shadow: 4px 1px 4px -1px rgba(0, 0, 0, 0.2),
    2px 1px 5px 0px rgba(0, 0, 0, 0.14), 2px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.scroll-tab-button.right-full {
  cursor: pointer;
  box-shadow: -4px -1px 4px -1px rgba(0, 0, 0, 0.2),
    -2px -1px 5px 0px rgba(0, 0, 0, 0.14),
    -2px -1px 10px 0px rgba(0, 0, 0, 0.12);
}
.scroll-tab-button.left-full {
  cursor: pointer;
  box-shadow: 4px 1px 4px -1px rgba(0, 0, 0, 0.2),
    2px 1px 5px 0px rgba(0, 0, 0, 0.14), 2px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.tab-list {
  //width: 1000px;
  max-width: 100%;
  //border-bottom: solid 1px #ccc;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.tab-box {
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  min-width: 150px;
  overflow: visible !important;
  background: transparent;
  font-size: 14px;
  flex-grow: 1;
  flex-basis: 0;
  border-bottom: 1px solid #ccc;
  .tab-name-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.active-tab {
  border-bottom: 2px solid #3949ab;
}

.tab-list.cdk-drop-list-dragging .tab-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.tab-box {
  padding: 10px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  position: relative;
  z-index: 1;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
}

.tab-handle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ccc;
  cursor: move;
  width: 15px;
  height: 15px;
}
.info-menu-trigger {
  font-size: 12px;
  cursor: pointer;
}
.info-menu {
  overflow: visible !important;
  padding: 12px;
  width: fit-content !important;
  .info-menu-close {
    position: absolute;
    overflow: visible;
    left: -8px;
    top: -25px;
  }
}

.inner-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#si-wrapper .silc-btn {
  bottom: 10px !important;
  right: 10px !important;
}
#si-wrapper .silc-btn-button {
  background-size: 24px !important;
  height: 45px !important;
  width: 45px !important;
}

.list-item-divider {
  margin: 6px 0;
}

.expected-field-cell {
  text-align: left;
  padding: 5px;
  border: 1px solid #ddd;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bolder;
}
#silc-btn {
  left: 10px !important;
}
#siWidget-chat {
  left: 32px !important;
  bottom: 55px !important;
}

.mat-column-wrap {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 25% !important;
  width: 25% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  height: 150px;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.popover {
  max-width: 400px !important;
}

/**
* DataUploads
**/
.data-upload-info-table {
  height: calc(100vh - 400px);
}
/**
* directives
**/

@import "theme";
@import "./at-styles.scss";

a.link-style {
  color: $hyperlink !important;
  text-decoration: underline !important;
}
.view-reports-container {
  height: calc(100vh - 250px);
}
.report-scroll-container {
  height: calc(100vh - 360px);
  .ps.ps--active-x.ps--active-y > .ps__rail-x {
    //needed to x scroll over sticky columns
    z-index: 999;
  }
}
.error-charge-report-scroll-container {
  .ps.ps--active-x.ps--active-y > .ps__rail-x {
    //needed to x scroll over sticky columns
    z-index: 11;
  }
}
/** Joyride customizations*/
.joyride-step__container {
  border-radius: 4px;
}
