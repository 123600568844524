.mat-red {
  background-color: mat-color($mat-red, 500) !important;
  color: mat-contrast($mat-red, 500) !important;
}

.mat-red-50 {
  background-color: mat-color($mat-red, 50) !important;
  color: mat-contrast($mat-red, 50) !important;
}

.mat-red-100 {
  background-color: mat-color($mat-red, 100) !important;
  color: mat-contrast($mat-red, 100) !important;
}

.mat-red-200 {
  background-color: mat-color($mat-red, 200) !important;
  color: mat-contrast($mat-red, 200) !important;
}

.mat-red-300 {
  background-color: mat-color($mat-red, 300) !important;
  color: mat-contrast($mat-red, 300) !important;
}

.mat-red-400 {
  background-color: mat-color($mat-red, 400) !important;
  color: mat-contrast($mat-red, 400) !important;
}

.mat-red-500 {
  background-color: mat-color($mat-red, 500) !important;
  color: mat-contrast($mat-red, 500) !important;
}

.mat-red-600 {
  background-color: mat-color($mat-red, 600) !important;
  color: mat-contrast($mat-red, 600) !important;
}

.mat-red-700 {
  background-color: mat-color($mat-red, 700) !important;
  color: mat-contrast($mat-red, 700) !important;
}

.mat-red-800 {
  background-color: mat-color($mat-red, 800) !important;
  color: mat-contrast($mat-red, 800) !important;
}

.mat-red-900 {
  background-color: mat-color($mat-red, 900) !important;
  color: mat-contrast($mat-red, 900) !important;
}

.mat-red-A100 {
  background-color: mat-color($mat-red, A100) !important;
  color: mat-contrast($mat-red, A100) !important;
}

.mat-red-A200 {
  background-color: mat-color($mat-red, A200) !important;
  color: mat-contrast($mat-red, A200) !important;
}

.mat-red-A400 {
  background-color: mat-color($mat-red, A400) !important;
  color: mat-contrast($mat-red, A400) !important;
}

.mat-red-A700 {
  background-color: mat-color($mat-red, A700) !important;
  color: mat-contrast($mat-red, A700) !important;
}

.mat-pink {
  background-color: mat-color($mat-pink, 500) !important;
  color: mat-contrast($mat-pink, 500) !important;
}

.mat-pink-50 {
  background-color: mat-color($mat-pink, 50) !important;
  color: mat-contrast($mat-pink, 50) !important;
}

.mat-pink-100 {
  background-color: mat-color($mat-pink, 100) !important;
  color: mat-contrast($mat-pink, 100) !important;
}

.mat-pink-200 {
  background-color: mat-color($mat-pink, 200) !important;
  color: mat-contrast($mat-pink, 200) !important;
}

.mat-pink-300 {
  background-color: mat-color($mat-pink, 300) !important;
  color: mat-contrast($mat-pink, 300) !important;
}

.mat-pink-400 {
  background-color: mat-color($mat-pink, 400) !important;
  color: mat-contrast($mat-pink, 400) !important;
}

.mat-pink-500 {
  background-color: mat-color($mat-pink, 500) !important;
  color: mat-contrast($mat-pink, 500) !important;
}

.mat-pink-600 {
  background-color: mat-color($mat-pink, 600) !important;
  color: mat-contrast($mat-pink, 600) !important;
}

.mat-pink-700 {
  background-color: mat-color($mat-pink, 700) !important;
  color: mat-contrast($mat-pink, 700) !important;
}

.mat-pink-800 {
  background-color: mat-color($mat-pink, 800) !important;
  color: mat-contrast($mat-pink, 800) !important;
}

.mat-pink-900 {
  background-color: mat-color($mat-pink, 900) !important;
  color: mat-contrast($mat-pink, 900) !important;
}

.mat-pink-A100 {
  background-color: mat-color($mat-pink, A100) !important;
  color: mat-contrast($mat-pink, A100) !important;
}

.mat-pink-A200 {
  background-color: mat-color($mat-pink, A200) !important;
  color: mat-contrast($mat-pink, A200) !important;
}

.mat-pink-A400 {
  background-color: mat-color($mat-pink, A400) !important;
  color: mat-contrast($mat-pink, A400) !important;
}

.mat-pink-A700 {
  background-color: mat-color($mat-pink, A700) !important;
  color: mat-contrast($mat-pink, A700) !important;
}

.mat-purple {
  background-color: mat-color($mat-purple, 500) !important;
  color: mat-contrast($mat-purple, 500) !important;
}

.mat-purple-50 {
  background-color: mat-color($mat-purple, 50) !important;
  color: mat-contrast($mat-purple, 50) !important;
}

.mat-purple-100 {
  background-color: mat-color($mat-purple, 100) !important;
  color: mat-contrast($mat-purple, 100) !important;
}

.mat-purple-200 {
  background-color: mat-color($mat-purple, 200) !important;
  color: mat-contrast($mat-purple, 200) !important;
}

.mat-purple-300 {
  background-color: mat-color($mat-purple, 300) !important;
  color: mat-contrast($mat-purple, 300) !important;
}

.mat-purple-400 {
  background-color: mat-color($mat-purple, 400) !important;
  color: mat-contrast($mat-purple, 400) !important;
}

.mat-purple-500 {
  background-color: mat-color($mat-purple, 500) !important;
  color: mat-contrast($mat-purple, 500) !important;
}

.mat-purple-600 {
  background-color: mat-color($mat-purple, 600) !important;
  color: mat-contrast($mat-purple, 600) !important;
}

.mat-purple-700 {
  background-color: mat-color($mat-purple, 700) !important;
  color: mat-contrast($mat-purple, 700) !important;
}

.mat-purple-800 {
  background-color: mat-color($mat-purple, 800) !important;
  color: mat-contrast($mat-purple, 800) !important;
}

.mat-purple-900 {
  background-color: mat-color($mat-purple, 900) !important;
  color: mat-contrast($mat-purple, 900) !important;
}

.mat-purple-A100 {
  background-color: mat-color($mat-purple, A100) !important;
  color: mat-contrast($mat-purple, A100) !important;
}

.mat-purple-A200 {
  background-color: mat-color($mat-purple, A200) !important;
  color: mat-contrast($mat-purple, A200) !important;
}

.mat-purple-A400 {
  background-color: mat-color($mat-purple, A400) !important;
  color: mat-contrast($mat-purple, A400) !important;
}

.mat-purple-A700 {
  background-color: mat-color($mat-purple, A700) !important;
  color: mat-contrast($mat-purple, A700) !important;
}

.mat-deep-purple {
  background-color: mat-color($mat-deep-purple, 500) !important;
  color: mat-contrast($mat-deep-purple, 500) !important;
}

.mat-deep-purple-50 {
  background-color: mat-color($mat-deep-purple, 50) !important;
  color: mat-contrast($mat-deep-purple, 50) !important;
}

.mat-deep-purple-100 {
  background-color: mat-color($mat-deep-purple, 100) !important;
  color: mat-contrast($mat-deep-purple, 100) !important;
}

.mat-deep-purple-200 {
  background-color: mat-color($mat-deep-purple, 200) !important;
  color: mat-contrast($mat-deep-purple, 200) !important;
}

.mat-deep-purple-300 {
  background-color: mat-color($mat-deep-purple, 300) !important;
  color: mat-contrast($mat-deep-purple, 300) !important;
}

.mat-deep-purple-400 {
  background-color: mat-color($mat-deep-purple, 400) !important;
  color: mat-contrast($mat-deep-purple, 400) !important;
}

.mat-deep-purple-500 {
  background-color: mat-color($mat-deep-purple, 500) !important;
  color: mat-contrast($mat-deep-purple, 500) !important;
}

.mat-deep-purple-600 {
  background-color: mat-color($mat-deep-purple, 600) !important;
  color: mat-contrast($mat-deep-purple, 600) !important;
}

.mat-deep-purple-700 {
  background-color: mat-color($mat-deep-purple, 700) !important;
  color: mat-contrast($mat-deep-purple, 700) !important;
}

.mat-deep-purple-800 {
  background-color: mat-color($mat-deep-purple, 800) !important;
  color: mat-contrast($mat-deep-purple, 800) !important;
}

.mat-deep-purple-900 {
  background-color: mat-color($mat-deep-purple, 900) !important;
  color: mat-contrast($mat-deep-purple, 900) !important;
}

.mat-deep-purple-A100 {
  background-color: mat-color($mat-deep-purple, A100) !important;
  color: mat-contrast($mat-deep-purple, A100) !important;
}

.mat-deep-purple-A200 {
  background-color: mat-color($mat-deep-purple, A200) !important;
  color: mat-contrast($mat-deep-purple, A200) !important;
}

.mat-deep-purple-A400 {
  background-color: mat-color($mat-deep-purple, A400) !important;
  color: mat-contrast($mat-deep-purple, A400) !important;
}

.mat-deep-purple-A700 {
  background-color: mat-color($mat-deep-purple, A700) !important;
  color: mat-contrast($mat-deep-purple, A700) !important;
}

.mat-indigo {
  background-color: mat-color($mat-indigo, 500) !important;
  color: mat-contrast($mat-indigo, 500) !important;
}

.mat-indigo-50 {
  background-color: mat-color($mat-indigo, 50) !important;
  color: mat-contrast($mat-indigo, 50) !important;
}

.mat-indigo-100 {
  background-color: mat-color($mat-indigo, 100) !important;
  color: mat-contrast($mat-indigo, 100) !important;
}

.mat-indigo-200 {
  background-color: mat-color($mat-indigo, 200) !important;
  color: mat-contrast($mat-indigo, 200) !important;
}

.mat-indigo-300 {
  background-color: mat-color($mat-indigo, 300) !important;
  color: mat-contrast($mat-indigo, 300) !important;
}

.mat-indigo-400 {
  background-color: mat-color($mat-indigo, 400) !important;
  color: mat-contrast($mat-indigo, 400) !important;
}

.mat-indigo-500 {
  background-color: mat-color($mat-indigo, 500) !important;
  color: mat-contrast($mat-indigo, 500) !important;
}

.mat-indigo-600 {
  background-color: mat-color($mat-indigo, 600) !important;
  color: mat-contrast($mat-indigo, 600) !important;
}

.mat-indigo-700 {
  background-color: mat-color($mat-indigo, 700) !important;
  color: mat-contrast($mat-indigo, 700) !important;
}

.mat-indigo-800 {
  background-color: mat-color($mat-indigo, 800) !important;
  color: mat-contrast($mat-indigo, 800) !important;
}

.mat-indigo-900 {
  background-color: mat-color($mat-indigo, 900) !important;
  color: mat-contrast($mat-indigo, 900) !important;
}

.mat-indigo-A100 {
  background-color: mat-color($mat-indigo, A100) !important;
  color: mat-contrast($mat-indigo, A100) !important;
}

.mat-indigo-A200 {
  background-color: mat-color($mat-indigo, A200) !important;
  color: mat-contrast($mat-indigo, A200) !important;
}

.mat-indigo-A400 {
  background-color: mat-color($mat-indigo, A400) !important;
  color: mat-contrast($mat-indigo, A400) !important;
}

.mat-indigo-A700 {
  background-color: mat-color($mat-indigo, A700) !important;
  color: mat-contrast($mat-indigo, A700) !important;
}

.mat-blue {
  background-color: mat-color($mat-blue, 500) !important;
  color: mat-contrast($mat-blue, 500) !important;
}

.mat-blue-50 {
  background-color: mat-color($mat-blue, 50) !important;
  color: mat-contrast($mat-blue, 50) !important;
}

.mat-blue-100 {
  background-color: mat-color($mat-blue, 100) !important;
  color: mat-contrast($mat-blue, 100) !important;
}

.mat-blue-200 {
  background-color: mat-color($mat-blue, 200) !important;
  color: mat-contrast($mat-blue, 200) !important;
}

.mat-blue-300 {
  background-color: mat-color($mat-blue, 300) !important;
  color: mat-contrast($mat-blue, 300) !important;
}

.mat-blue-400 {
  background-color: mat-color($mat-blue, 400) !important;
  color: mat-contrast($mat-blue, 400) !important;
}

.mat-blue-500 {
  background-color: mat-color($mat-blue, 500) !important;
  color: mat-contrast($mat-blue, 500) !important;
}

.mat-blue-600 {
  background-color: mat-color($mat-blue, 600) !important;
  color: mat-contrast($mat-blue, 600) !important;
}

.mat-blue-700 {
  background-color: mat-color($mat-blue, 700) !important;
  color: mat-contrast($mat-blue, 700) !important;
}

.mat-blue-800 {
  background-color: mat-color($mat-blue, 800) !important;
  color: mat-contrast($mat-blue, 800) !important;
}

.mat-blue-900 {
  background-color: mat-color($mat-blue, 900) !important;
  color: mat-contrast($mat-blue, 900) !important;
}

.mat-blue-A100 {
  background-color: mat-color($mat-blue, A100) !important;
  color: mat-contrast($mat-blue, A100) !important;
}

.mat-blue-A200 {
  background-color: mat-color($mat-blue, A200) !important;
  color: mat-contrast($mat-blue, A200) !important;
}

.mat-blue-A400 {
  background-color: mat-color($mat-blue, A400) !important;
  color: mat-contrast($mat-blue, A400) !important;
}

.mat-blue-A700 {
  background-color: mat-color($mat-blue, A700) !important;
  color: mat-contrast($mat-blue, A700) !important;
}

.mat-light-blue {
  background-color: mat-color($mat-light-blue, 500) !important;
  color: mat-contrast($mat-light-blue, 500) !important;
}

.mat-light-blue-50 {
  background-color: mat-color($mat-light-blue, 50) !important;
  color: mat-contrast($mat-light-blue, 50) !important;
}

.mat-light-blue-100 {
  background-color: mat-color($mat-light-blue, 100) !important;
  color: mat-contrast($mat-light-blue, 100) !important;
}

.mat-light-blue-200 {
  background-color: mat-color($mat-light-blue, 200) !important;
  color: mat-contrast($mat-light-blue, 200) !important;
}

.mat-light-blue-300 {
  background-color: mat-color($mat-light-blue, 300) !important;
  color: mat-contrast($mat-light-blue, 300) !important;
}

.mat-light-blue-400 {
  background-color: mat-color($mat-light-blue, 400) !important;
  color: mat-contrast($mat-light-blue, 400) !important;
}

.mat-light-blue-500 {
  background-color: mat-color($mat-light-blue, 500) !important;
  color: mat-contrast($mat-light-blue, 500) !important;
}

.mat-light-blue-600 {
  background-color: mat-color($mat-light-blue, 600) !important;
  color: mat-contrast($mat-light-blue, 600) !important;
}

.mat-light-blue-700 {
  background-color: mat-color($mat-light-blue, 700) !important;
  color: mat-contrast($mat-light-blue, 700) !important;
}

.mat-light-blue-800 {
  background-color: mat-color($mat-light-blue, 800) !important;
  color: mat-contrast($mat-light-blue, 800) !important;
}

.mat-light-blue-900 {
  background-color: mat-color($mat-light-blue, 900) !important;
  color: mat-contrast($mat-light-blue, 900) !important;
}

.mat-light-blue-A100 {
  background-color: mat-color($mat-light-blue, A100) !important;
  color: mat-contrast($mat-light-blue, A100) !important;
}

.mat-light-blue-A200 {
  background-color: mat-color($mat-light-blue, A200) !important;
  color: mat-contrast($mat-light-blue, A200) !important;
}

.mat-light-blue-A400 {
  background-color: mat-color($mat-light-blue, A400) !important;
  color: mat-contrast($mat-light-blue, A400) !important;
}

.mat-light-blue-A700 {
  background-color: mat-color($mat-light-blue, A700) !important;
  color: mat-contrast($mat-light-blue, A700) !important;
}

.mat-cyan {
  background-color: mat-color($mat-cyan, 500) !important;
  color: mat-contrast($mat-cyan, 500) !important;
}

.mat-cyan-50 {
  background-color: mat-color($mat-cyan, 50) !important;
  color: mat-contrast($mat-cyan, 50) !important;
}

.mat-cyan-100 {
  background-color: mat-color($mat-cyan, 100) !important;
  color: mat-contrast($mat-cyan, 100) !important;
}

.mat-cyan-200 {
  background-color: mat-color($mat-cyan, 200) !important;
  color: mat-contrast($mat-cyan, 200) !important;
}

.mat-cyan-300 {
  background-color: mat-color($mat-cyan, 300) !important;
  color: mat-contrast($mat-cyan, 300) !important;
}

.mat-cyan-400 {
  background-color: mat-color($mat-cyan, 400) !important;
  color: mat-contrast($mat-cyan, 400) !important;
}

.mat-cyan-500 {
  background-color: mat-color($mat-cyan, 500) !important;
  color: mat-contrast($mat-cyan, 500) !important;
}

.mat-cyan-600 {
  background-color: mat-color($mat-cyan, 600) !important;
  color: mat-contrast($mat-cyan, 600) !important;
}

.mat-cyan-700 {
  background-color: mat-color($mat-cyan, 700) !important;
  color: mat-contrast($mat-cyan, 700) !important;
}

.mat-cyan-800 {
  background-color: mat-color($mat-cyan, 800) !important;
  color: mat-contrast($mat-cyan, 800) !important;
}

.mat-cyan-900 {
  background-color: mat-color($mat-cyan, 900) !important;
  color: mat-contrast($mat-cyan, 900) !important;
}

.mat-cyan-A100 {
  background-color: mat-color($mat-cyan, A100) !important;
  color: mat-contrast($mat-cyan, A100) !important;
}

.mat-cyan-A200 {
  background-color: mat-color($mat-cyan, A200) !important;
  color: mat-contrast($mat-cyan, A200) !important;
}

.mat-cyan-A400 {
  background-color: mat-color($mat-cyan, A400) !important;
  color: mat-contrast($mat-cyan, A400) !important;
}

.mat-cyan-A700 {
  background-color: mat-color($mat-cyan, A700) !important;
  color: mat-contrast($mat-cyan, A700) !important;
}

.mat-teal {
  background-color: mat-color($mat-teal, 500) !important;
  color: mat-contrast($mat-teal, 500) !important;
}

.mat-teal-50 {
  background-color: mat-color($mat-teal, 50) !important;
  color: mat-contrast($mat-teal, 50) !important;
}

.mat-teal-100 {
  background-color: mat-color($mat-teal, 100) !important;
  color: mat-contrast($mat-teal, 100) !important;
}

.mat-teal-200 {
  background-color: mat-color($mat-teal, 200) !important;
  color: mat-contrast($mat-teal, 200) !important;
}

.mat-teal-300 {
  background-color: mat-color($mat-teal, 300) !important;
  color: mat-contrast($mat-teal, 300) !important;
}

.mat-teal-400 {
  background-color: mat-color($mat-teal, 400) !important;
  color: mat-contrast($mat-teal, 400) !important;
}

.mat-teal-500 {
  background-color: mat-color($mat-teal, 500) !important;
  color: mat-contrast($mat-teal, 500) !important;
}

.mat-teal-600 {
  background-color: mat-color($mat-teal, 600) !important;
  color: mat-contrast($mat-teal, 600) !important;
}

.mat-teal-700 {
  background-color: mat-color($mat-teal, 700) !important;
  color: mat-contrast($mat-teal, 700) !important;
}

.mat-teal-800 {
  background-color: mat-color($mat-teal, 800) !important;
  color: mat-contrast($mat-teal, 800) !important;
}

.mat-teal-900 {
  background-color: mat-color($mat-teal, 900) !important;
  color: mat-contrast($mat-teal, 900) !important;
}

.mat-teal-A100 {
  background-color: mat-color($mat-teal, A100) !important;
  color: mat-contrast($mat-teal, A100) !important;
}

.mat-teal-A200 {
  background-color: mat-color($mat-teal, A200) !important;
  color: mat-contrast($mat-teal, A200) !important;
}

.mat-teal-A400 {
  background-color: mat-color($mat-teal, A400) !important;
  color: mat-contrast($mat-teal, A400) !important;
}

.mat-teal-A700 {
  background-color: mat-color($mat-teal, A700) !important;
  color: mat-contrast($mat-teal, A700) !important;
}

.mat-green {
  background-color: mat-color($mat-green, 500) !important;
  color: mat-contrast($mat-green, 500) !important;
}

.mat-green-50 {
  background-color: mat-color($mat-green, 50) !important;
  color: mat-contrast($mat-green, 50) !important;
}

.mat-green-100 {
  background-color: mat-color($mat-green, 100) !important;
  color: mat-contrast($mat-green, 100) !important;
}

.mat-green-200 {
  background-color: mat-color($mat-green, 200) !important;
  color: mat-contrast($mat-green, 200) !important;
}

.mat-green-300 {
  background-color: mat-color($mat-green, 300) !important;
  color: mat-contrast($mat-green, 300) !important;
}

.mat-green-400 {
  background-color: mat-color($mat-green, 400) !important;
  color: mat-contrast($mat-green, 400) !important;
}

.mat-green-500 {
  background-color: mat-color($mat-green, 500) !important;
  color: mat-contrast($mat-green, 500) !important;
}

.mat-green-600 {
  background-color: mat-color($mat-green, 600) !important;
  color: mat-contrast($mat-green, 600) !important;
}

.mat-green-700 {
  background-color: mat-color($mat-green, 700) !important;
  color: mat-contrast($mat-green, 700) !important;
}

.mat-green-800 {
  background-color: mat-color($mat-green, 800) !important;
  color: mat-contrast($mat-green, 800) !important;
}

.mat-green-900 {
  background-color: mat-color($mat-green, 900) !important;
  color: mat-contrast($mat-green, 900) !important;
}

.mat-green-A100 {
  background-color: mat-color($mat-green, A100) !important;
  color: mat-contrast($mat-green, A100) !important;
}

.mat-green-A200 {
  background-color: mat-color($mat-green, A200) !important;
  color: mat-contrast($mat-green, A200) !important;
}

.mat-green-A400 {
  background-color: mat-color($mat-green, A400) !important;
  color: mat-contrast($mat-green, A400) !important;
}

.mat-green-A700 {
  background-color: mat-color($mat-green, A700) !important;
  color: mat-contrast($mat-green, A700) !important;
}

.mat-light-green {
  background-color: mat-color($mat-light-green, 500) !important;
  color: mat-contrast($mat-light-green, 500) !important;
}

.mat-light-green-50 {
  background-color: mat-color($mat-light-green, 50) !important;
  color: mat-contrast($mat-light-green, 50) !important;
}

.mat-light-green-100 {
  background-color: mat-color($mat-light-green, 100) !important;
  color: mat-contrast($mat-light-green, 100) !important;
}

.mat-light-green-200 {
  background-color: mat-color($mat-light-green, 200) !important;
  color: mat-contrast($mat-light-green, 200) !important;
}

.mat-light-green-300 {
  background-color: mat-color($mat-light-green, 300) !important;
  color: mat-contrast($mat-light-green, 300) !important;
}

.mat-light-green-400 {
  background-color: mat-color($mat-light-green, 400) !important;
  color: mat-contrast($mat-light-green, 400) !important;
}

.mat-light-green-500 {
  background-color: mat-color($mat-light-green, 500) !important;
  color: mat-contrast($mat-light-green, 500) !important;
}

.mat-light-green-600 {
  background-color: mat-color($mat-light-green, 600) !important;
  color: mat-contrast($mat-light-green, 600) !important;
}

.mat-light-green-700 {
  background-color: mat-color($mat-light-green, 700) !important;
  color: mat-contrast($mat-light-green, 700) !important;
}

.mat-light-green-800 {
  background-color: mat-color($mat-light-green, 800) !important;
  color: mat-contrast($mat-light-green, 800) !important;
}

.mat-light-green-900 {
  background-color: mat-color($mat-light-green, 900) !important;
  color: mat-contrast($mat-light-green, 900) !important;
}

.mat-light-green-A100 {
  background-color: mat-color($mat-light-green, A100) !important;
  color: mat-contrast($mat-light-green, A100) !important;
}

.mat-light-green-A200 {
  background-color: mat-color($mat-light-green, A200) !important;
  color: mat-contrast($mat-light-green, A200) !important;
}

.mat-light-green-A400 {
  background-color: mat-color($mat-light-green, A400) !important;
  color: mat-contrast($mat-light-green, A400) !important;
}

.mat-light-green-A700 {
  background-color: mat-color($mat-light-green, A700) !important;
  color: mat-contrast($mat-light-green, A700) !important;
}

.mat-lime {
  background-color: mat-color($mat-lime, 500) !important;
  color: mat-contrast($mat-lime, 500) !important;
}

.mat-lime-50 {
  background-color: mat-color($mat-lime, 50) !important;
  color: mat-contrast($mat-lime, 50) !important;
}

.mat-lime-100 {
  background-color: mat-color($mat-lime, 100) !important;
  color: mat-contrast($mat-lime, 100) !important;
}

.mat-lime-200 {
  background-color: mat-color($mat-lime, 200) !important;
  color: mat-contrast($mat-lime, 200) !important;
}

.mat-lime-300 {
  background-color: mat-color($mat-lime, 300) !important;
  color: mat-contrast($mat-lime, 300) !important;
}

.mat-lime-400 {
  background-color: mat-color($mat-lime, 400) !important;
  color: mat-contrast($mat-lime, 400) !important;
}

.mat-lime-500 {
  background-color: mat-color($mat-lime, 500) !important;
  color: mat-contrast($mat-lime, 500) !important;
}

.mat-lime-600 {
  background-color: mat-color($mat-lime, 600) !important;
  color: mat-contrast($mat-lime, 600) !important;
}

.mat-lime-700 {
  background-color: mat-color($mat-lime, 700) !important;
  color: mat-contrast($mat-lime, 700) !important;
}

.mat-lime-800 {
  background-color: mat-color($mat-lime, 800) !important;
  color: mat-contrast($mat-lime, 800) !important;
}

.mat-lime-900 {
  background-color: mat-color($mat-lime, 900) !important;
  color: mat-contrast($mat-lime, 900) !important;
}

.mat-lime-A100 {
  background-color: mat-color($mat-lime, A100) !important;
  color: mat-contrast($mat-lime, A100) !important;
}

.mat-lime-A200 {
  background-color: mat-color($mat-lime, A200) !important;
  color: mat-contrast($mat-lime, A200) !important;
}

.mat-lime-A400 {
  background-color: mat-color($mat-lime, A400) !important;
  color: mat-contrast($mat-lime, A400) !important;
}

.mat-lime-A700 {
  background-color: mat-color($mat-lime, A700) !important;
  color: mat-contrast($mat-lime, A700) !important;
}

.mat-yellow {
  background-color: mat-color($mat-yellow, 500) !important;
  color: mat-contrast($mat-yellow, 500) !important;
}

.mat-yellow-50 {
  background-color: mat-color($mat-yellow, 50) !important;
  color: mat-contrast($mat-yellow, 50) !important;
}

.mat-yellow-100 {
  background-color: mat-color($mat-yellow, 100) !important;
  color: mat-contrast($mat-yellow, 100) !important;
}

.mat-yellow-200 {
  background-color: mat-color($mat-yellow, 200) !important;
  color: mat-contrast($mat-yellow, 200) !important;
}

.mat-yellow-300 {
  background-color: mat-color($mat-yellow, 300) !important;
  color: mat-contrast($mat-yellow, 300) !important;
}

.mat-yellow-400 {
  background-color: mat-color($mat-yellow, 400) !important;
  color: mat-contrast($mat-yellow, 400) !important;
}

.mat-yellow-500 {
  background-color: mat-color($mat-yellow, 500) !important;
  color: mat-contrast($mat-yellow, 500) !important;
}

.mat-yellow-600 {
  background-color: mat-color($mat-yellow, 600) !important;
  color: mat-contrast($mat-yellow, 600) !important;
}

.mat-yellow-700 {
  background-color: mat-color($mat-yellow, 700) !important;
  color: mat-contrast($mat-yellow, 700) !important;
}

.mat-yellow-800 {
  background-color: mat-color($mat-yellow, 800) !important;
  color: mat-contrast($mat-yellow, 800) !important;
}

.mat-yellow-900 {
  background-color: mat-color($mat-yellow, 900) !important;
  color: mat-contrast($mat-yellow, 900) !important;
}

.mat-yellow-A100 {
  background-color: mat-color($mat-yellow, A100) !important;
  color: mat-contrast($mat-yellow, A100) !important;
}

.mat-yellow-A200 {
  background-color: mat-color($mat-yellow, A200) !important;
  color: mat-contrast($mat-yellow, A200) !important;
}

.mat-yellow-A400 {
  background-color: mat-color($mat-yellow, A400) !important;
  color: mat-contrast($mat-yellow, A400) !important;
}

.mat-yellow-A700 {
  background-color: mat-color($mat-yellow, A700) !important;
  color: mat-contrast($mat-yellow, A700) !important;
}

.mat-amber {
  background-color: mat-color($mat-amber, 500) !important;
  color: mat-contrast($mat-amber, 500) !important;
}

.mat-amber-50 {
  background-color: mat-color($mat-amber, 50) !important;
  color: mat-contrast($mat-amber, 50) !important;
}

.mat-amber-100 {
  background-color: mat-color($mat-amber, 100) !important;
  color: mat-contrast($mat-amber, 100) !important;
}

.mat-amber-200 {
  background-color: mat-color($mat-amber, 200) !important;
  color: mat-contrast($mat-amber, 200) !important;
}

.mat-amber-300 {
  background-color: mat-color($mat-amber, 300) !important;
  color: mat-contrast($mat-amber, 300) !important;
}

.mat-amber-400 {
  background-color: mat-color($mat-amber, 400) !important;
  color: mat-contrast($mat-amber, 400) !important;
}

.mat-amber-500 {
  background-color: mat-color($mat-amber, 500) !important;
  color: mat-contrast($mat-amber, 500) !important;
}

.mat-amber-600 {
  background-color: mat-color($mat-amber, 600) !important;
  color: mat-contrast($mat-amber, 600) !important;
}

.mat-amber-700 {
  background-color: mat-color($mat-amber, 700) !important;
  color: mat-contrast($mat-amber, 700) !important;
}

.mat-amber-800 {
  background-color: mat-color($mat-amber, 800) !important;
  color: mat-contrast($mat-amber, 800) !important;
}

.mat-amber-900 {
  background-color: mat-color($mat-amber, 900) !important;
  color: mat-contrast($mat-amber, 900) !important;
}

.mat-amber-A100 {
  background-color: mat-color($mat-amber, A100) !important;
  color: mat-contrast($mat-amber, A100) !important;
}

.mat-amber-A200 {
  background-color: mat-color($mat-amber, A200) !important;
  color: mat-contrast($mat-amber, A200) !important;
}

.mat-amber-A400 {
  background-color: mat-color($mat-amber, A400) !important;
  color: mat-contrast($mat-amber, A400) !important;
}

.mat-amber-A700 {
  background-color: mat-color($mat-amber, A700) !important;
  color: mat-contrast($mat-amber, A700) !important;
}

.mat-orange {
  background-color: mat-color($mat-orange, 500) !important;
  color: mat-contrast($mat-orange, 500) !important;
}

.mat-orange-50 {
  background-color: mat-color($mat-orange, 50) !important;
  color: mat-contrast($mat-orange, 50) !important;
}

.mat-orange-100 {
  background-color: mat-color($mat-orange, 100) !important;
  color: mat-contrast($mat-orange, 100) !important;
}

.mat-orange-200 {
  background-color: mat-color($mat-orange, 200) !important;
  color: mat-contrast($mat-orange, 200) !important;
}

.mat-orange-300 {
  background-color: mat-color($mat-orange, 300) !important;
  color: mat-contrast($mat-orange, 300) !important;
}

.mat-orange-400 {
  background-color: mat-color($mat-orange, 400) !important;
  color: mat-contrast($mat-orange, 400) !important;
}

.mat-orange-500 {
  background-color: mat-color($mat-orange, 500) !important;
  color: mat-contrast($mat-orange, 500) !important;
}

.mat-orange-600 {
  background-color: mat-color($mat-orange, 600) !important;
  color: mat-contrast($mat-orange, 600) !important;
}

.mat-orange-700 {
  background-color: mat-color($mat-orange, 700) !important;
  color: mat-contrast($mat-orange, 700) !important;
}

.mat-orange-800 {
  background-color: mat-color($mat-orange, 800) !important;
  color: mat-contrast($mat-orange, 800) !important;
}

.mat-orange-900 {
  background-color: mat-color($mat-orange, 900) !important;
  color: mat-contrast($mat-orange, 900) !important;
}

.mat-orange-A100 {
  background-color: mat-color($mat-orange, A100) !important;
  color: mat-contrast($mat-orange, A100) !important;
}

.mat-orange-A200 {
  background-color: mat-color($mat-orange, A200) !important;
  color: mat-contrast($mat-orange, A200) !important;
}

.mat-orange-A400 {
  background-color: mat-color($mat-orange, A400) !important;
  color: mat-contrast($mat-orange, A400) !important;
}

.mat-orange-A700 {
  background-color: mat-color($mat-orange, A700) !important;
  color: mat-contrast($mat-orange, A700) !important;
}

.mat-deep-orange {
  background-color: mat-color($mat-deep-orange, 500) !important;
  color: mat-contrast($mat-deep-orange, 500) !important;
}

.mat-deep-orange-50 {
  background-color: mat-color($mat-deep-orange, 50) !important;
  color: mat-contrast($mat-deep-orange, 50) !important;
}

.mat-deep-orange-100 {
  background-color: mat-color($mat-deep-orange, 100) !important;
  color: mat-contrast($mat-deep-orange, 100) !important;
}

.mat-deep-orange-200 {
  background-color: mat-color($mat-deep-orange, 200) !important;
  color: mat-contrast($mat-deep-orange, 200) !important;
}

.mat-deep-orange-300 {
  background-color: mat-color($mat-deep-orange, 300) !important;
  color: mat-contrast($mat-deep-orange, 300) !important;
}

.mat-deep-orange-400 {
  background-color: mat-color($mat-deep-orange, 400) !important;
  color: mat-contrast($mat-deep-orange, 400) !important;
}

.mat-deep-orange-500 {
  background-color: mat-color($mat-deep-orange, 500) !important;
  color: mat-contrast($mat-deep-orange, 500) !important;
}

.mat-deep-orange-600 {
  background-color: mat-color($mat-deep-orange, 600) !important;
  color: mat-contrast($mat-deep-orange, 600) !important;
}

.mat-deep-orange-700 {
  background-color: mat-color($mat-deep-orange, 700) !important;
  color: mat-contrast($mat-deep-orange, 700) !important;
}

.mat-deep-orange-800 {
  background-color: mat-color($mat-deep-orange, 800) !important;
  color: mat-contrast($mat-deep-orange, 800) !important;
}

.mat-deep-orange-900 {
  background-color: mat-color($mat-deep-orange, 900) !important;
  color: mat-contrast($mat-deep-orange, 900) !important;
}

.mat-deep-orange-A100 {
  background-color: mat-color($mat-deep-orange, A100) !important;
  color: mat-contrast($mat-deep-orange, A100) !important;
}

.mat-deep-orange-A200 {
  background-color: mat-color($mat-deep-orange, A200) !important;
  color: mat-contrast($mat-deep-orange, A200) !important;
}

.mat-deep-orange-A400 {
  background-color: mat-color($mat-deep-orange, A400) !important;
  color: mat-contrast($mat-deep-orange, A400) !important;
}

.mat-deep-orange-A700 {
  background-color: mat-color($mat-deep-orange, A700) !important;
  color: mat-contrast($mat-deep-orange, A700) !important;
}

.mat-brown {
  background-color: mat-color($mat-brown, 500) !important;
  color: mat-contrast($mat-brown, 500) !important;
}

.mat-brown-50 {
  background-color: mat-color($mat-brown, 50) !important;
  color: mat-contrast($mat-brown, 50) !important;
}

.mat-brown-100 {
  background-color: mat-color($mat-brown, 100) !important;
  color: mat-contrast($mat-brown, 100) !important;
}

.mat-brown-200 {
  background-color: mat-color($mat-brown, 200) !important;
  color: mat-contrast($mat-brown, 200) !important;
}

.mat-brown-300 {
  background-color: mat-color($mat-brown, 300) !important;
  color: mat-contrast($mat-brown, 300) !important;
}

.mat-brown-400 {
  background-color: mat-color($mat-brown, 400) !important;
  color: mat-contrast($mat-brown, 400) !important;
}

.mat-brown-500 {
  background-color: mat-color($mat-brown, 500) !important;
  color: mat-contrast($mat-brown, 500) !important;
}

.mat-brown-600 {
  background-color: mat-color($mat-brown, 600) !important;
  color: mat-contrast($mat-brown, 600) !important;
}

.mat-brown-700 {
  background-color: mat-color($mat-brown, 700) !important;
  color: mat-contrast($mat-brown, 700) !important;
}

.mat-brown-800 {
  background-color: mat-color($mat-brown, 800) !important;
  color: mat-contrast($mat-brown, 800) !important;
}

.mat-brown-900 {
  background-color: mat-color($mat-brown, 900) !important;
  color: mat-contrast($mat-brown, 900) !important;
}

.mat-brown-A100 {
  background-color: mat-color($mat-brown, A100) !important;
  color: mat-contrast($mat-brown, A100) !important;
}

.mat-brown-A200 {
  background-color: mat-color($mat-brown, A200) !important;
  color: mat-contrast($mat-brown, A200) !important;
}

.mat-brown-A400 {
  background-color: mat-color($mat-brown, A400) !important;
  color: mat-contrast($mat-brown, A400) !important;
}

.mat-brown-A700 {
  background-color: mat-color($mat-brown, A700) !important;
  color: mat-contrast($mat-brown, A700) !important;
}

.mat-grey {
  background-color: mat-color($mat-grey, 500) !important;
  color: mat-contrast($mat-grey, 500) !important;
}

.mat-grey-50 {
  background-color: mat-color($mat-grey, 50) !important;
  color: mat-contrast($mat-grey, 50) !important;
}

.mat-grey-100 {
  background-color: mat-color($mat-grey, 100) !important;
  color: mat-contrast($mat-grey, 100) !important;
}

.mat-grey-200 {
  background-color: mat-color($mat-grey, 200) !important;
  color: mat-contrast($mat-grey, 200) !important;
}

.mat-grey-300 {
  background-color: mat-color($mat-grey, 300) !important;
  color: mat-contrast($mat-grey, 300) !important;
}

.mat-grey-400 {
  background-color: mat-color($mat-grey, 400) !important;
  color: mat-contrast($mat-grey, 400) !important;
}

.mat-grey-500 {
  background-color: mat-color($mat-grey, 500) !important;
  color: mat-contrast($mat-grey, 500) !important;
}

.mat-grey-600 {
  background-color: mat-color($mat-grey, 600) !important;
  color: mat-contrast($mat-grey, 600) !important;
}

.mat-grey-700 {
  background-color: mat-color($mat-grey, 700) !important;
  color: mat-contrast($mat-grey, 700) !important;
}

.mat-grey-800 {
  background-color: mat-color($mat-grey, 800) !important;
  color: mat-contrast($mat-grey, 800) !important;
}

.mat-grey-900 {
  background-color: mat-color($mat-grey, 900) !important;
  color: mat-contrast($mat-grey, 900) !important;
}

.mat-grey-A100 {
  background-color: mat-color($mat-grey, A100) !important;
  color: mat-contrast($mat-grey, A100) !important;
}

.mat-grey-A200 {
  background-color: mat-color($mat-grey, A200) !important;
  color: mat-contrast($mat-grey, A200) !important;
}

.mat-grey-A400 {
  background-color: mat-color($mat-grey, A400) !important;
  color: mat-contrast($mat-grey, A400) !important;
}

.mat-grey-A700 {
  background-color: mat-color($mat-grey, A700) !important;
  color: mat-contrast($mat-grey, A700) !important;
}

.mat-blue-grey {
  background-color: mat-color($mat-blue-grey, 500) !important;
  color: mat-contrast($mat-blue-grey, 500) !important;
}

.mat-blue-grey-50 {
  background-color: mat-color($mat-blue-grey, 50) !important;
  color: mat-contrast($mat-blue-grey, 50) !important;
}

.mat-blue-grey-100 {
  background-color: mat-color($mat-blue-grey, 100) !important;
  color: mat-contrast($mat-blue-grey, 100) !important;
}

.mat-blue-grey-200 {
  background-color: mat-color($mat-blue-grey, 200) !important;
  color: mat-contrast($mat-blue-grey, 200) !important;
}

.mat-blue-grey-300 {
  background-color: mat-color($mat-blue-grey, 300) !important;
  color: mat-contrast($mat-blue-grey, 300) !important;
}

.mat-blue-grey-400 {
  background-color: mat-color($mat-blue-grey, 400) !important;
  color: mat-contrast($mat-blue-grey, 400) !important;
}

.mat-blue-grey-500 {
  background-color: mat-color($mat-blue-grey, 500) !important;
  color: mat-contrast($mat-blue-grey, 500) !important;
}

.mat-blue-grey-600 {
  background-color: mat-color($mat-blue-grey, 600) !important;
  color: mat-contrast($mat-blue-grey, 600) !important;
}

.mat-blue-grey-700 {
  background-color: mat-color($mat-blue-grey, 700) !important;
  color: mat-contrast($mat-blue-grey, 700) !important;
}

.mat-blue-grey-800 {
  background-color: mat-color($mat-blue-grey, 800) !important;
  color: mat-contrast($mat-blue-grey, 800) !important;
}

.mat-blue-grey-900 {
  background-color: mat-color($mat-blue-grey, 900) !important;
  color: mat-contrast($mat-blue-grey, 900) !important;
}

.mat-blue-grey-A100 {
  background-color: mat-color($mat-blue-grey, A100) !important;
  color: mat-contrast($mat-blue-grey, A100) !important;
}

.mat-blue-grey-A200 {
  background-color: mat-color($mat-blue-grey, A200) !important;
  color: mat-contrast($mat-blue-grey, A200) !important;
}

.mat-blue-grey-A400 {
  background-color: mat-color($mat-blue-grey, A400) !important;
  color: mat-contrast($mat-blue-grey, A400) !important;
}

.mat-blue-grey-A700 {
  background-color: mat-color($mat-blue-grey, A700) !important;
  color: mat-contrast($mat-blue-grey, A700) !important;
}
