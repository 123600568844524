.audit-trail-document-tab-container {
  padding-top: 14px;
}
mat-list-item {
  cursor: pointer !important;
}
mat-list-item:hover {
 background: lightgrey
}
.section-list-item {
  height: 100%;
  width: 100%;
  margin-left: -15px !important;
  padding: 0 15px !important;
}
mat-accordion.mat-accordion {
  margin-top: 20px;
}
mat-panel-title {
  white-space: nowrap;
  overflow: hidden;

  div {
    overflow: hidden;
  }
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
  font-size: 16px;
  font-weight: normal;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

//.mat-badge-content {
//  right: 15px !important;
//  top: 2px !important;
//}
.comment-messages-container {
  width: 100%;
  box-shadow: 3px 4px 4px -1px rgba(0, 0, 0, 0.2);
  .mat-drawer-content {
    overflow-x: hidden !important;
  }
}
.comment-toolbar {
  height: fit-content !important;
  z-index: 99;
}
.sections-toolbar {
  z-index: 99;
}
.comment-toolbar-comment-container {
  height: 80px;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 10px;
}

.comment-toolbar-info {
  overflow: hidden;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.add-comment-button {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  z-index: 1;
}

.section-comment-list-toolbar {
  max-height: 64px;
}
.section-comment-list {
  // margin-top: 60px;
  mat-list-item {
    border-bottom: 1px solid whitesmoke;
  }
}

.section-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.panel-title-message {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.comment-expansion-panel {
  margin: 0 5px 5px;
}


:host {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
  display: block;
}

:host {
  .mat-sidenav,
  .mat-drawer-content {
    padding: 0;
    overflow-x: hidden;
  }

  md-toolbar + hr,
  md-card-content + hr {
    margin-left: -5px;
    margin-right: -5px;
  }
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.audit-trails-title-description-container {
  width: 35%;
  margin-left: -30px;
}
.comment-message-new-message-container {
  box-shadow: 2px -5px 4px -1px rgba(0, 0, 0, 0.2)
}
$mat-toolbar-height-desktop: 64px !default;
$mat-toolbar-height-mobile-portrait: 56px !default;
$mat-toolbar-height-mobile-landscape: 48px !default;
