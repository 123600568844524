.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #009688;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #009688;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #009688;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #009688;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #009688;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #009688;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #009688;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #009688;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #009688;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 150, 136, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 150, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 150, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #009688;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 150, 136, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 150, 136, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 150, 136, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #009688;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #009688;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #009688;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #009688;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #009688;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #009688;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #009688;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #009688;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #cbd0e9;
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bce1de;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bce1de;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #009688;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #009688;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #009688;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #009688;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #009688;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #009688;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 150, 136, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #009688;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #009688;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 150, 136, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #009688;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #009688;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #009688;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #009688;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #009688;
}

.app-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-option {
  color: white;
}
.app-dark .mat-option:hover:not(.mat-option-disabled), .app-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #607d8b;
}
.app-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}
.app-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app-dark .mat-primary .mat-pseudo-checkbox-checked,
.app-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #607d8b;
}
.app-dark .mat-pseudo-checkbox-checked,
.app-dark .mat-pseudo-checkbox-indeterminate,
.app-dark .mat-accent .mat-pseudo-checkbox-checked,
.app-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app-dark .mat-warn .mat-pseudo-checkbox-checked,
.app-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}
.app-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app-dark .mat-app-background, .app-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app-dark .mat-badge-content {
  color: white;
  background: #607d8b;
}
.cdk-high-contrast-active .app-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}
.app-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app-dark .mat-button, .app-dark .mat-icon-button, .app-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app-dark .mat-button.mat-primary, .app-dark .mat-icon-button.mat-primary, .app-dark .mat-stroked-button.mat-primary {
  color: #607d8b;
}
.app-dark .mat-button.mat-accent, .app-dark .mat-icon-button.mat-accent, .app-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app-dark .mat-button.mat-warn, .app-dark .mat-icon-button.mat-warn, .app-dark .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.app-dark .mat-button.mat-primary.mat-button-disabled, .app-dark .mat-button.mat-accent.mat-button-disabled, .app-dark .mat-button.mat-warn.mat-button-disabled, .app-dark .mat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-icon-button.mat-primary.mat-button-disabled, .app-dark .mat-icon-button.mat-accent.mat-button-disabled, .app-dark .mat-icon-button.mat-warn.mat-button-disabled, .app-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-button.mat-primary .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #607d8b;
}
.app-dark .mat-button.mat-accent .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app-dark .mat-button.mat-warn .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.app-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app-dark .mat-button .mat-ripple-element, .app-dark .mat-icon-button .mat-ripple-element, .app-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app-dark .mat-button-focus-overlay {
  background: white;
}
.app-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-flat-button, .app-dark .mat-raised-button, .app-dark .mat-fab, .app-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app-dark .mat-flat-button.mat-primary, .app-dark .mat-raised-button.mat-primary, .app-dark .mat-fab.mat-primary, .app-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app-dark .mat-flat-button.mat-accent, .app-dark .mat-raised-button.mat-accent, .app-dark .mat-fab.mat-accent, .app-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-flat-button.mat-warn, .app-dark .mat-raised-button.mat-warn, .app-dark .mat-fab.mat-warn, .app-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app-dark .mat-flat-button.mat-primary.mat-button-disabled, .app-dark .mat-flat-button.mat-accent.mat-button-disabled, .app-dark .mat-flat-button.mat-warn.mat-button-disabled, .app-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-raised-button.mat-primary.mat-button-disabled, .app-dark .mat-raised-button.mat-accent.mat-button-disabled, .app-dark .mat-raised-button.mat-warn.mat-button-disabled, .app-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-fab.mat-primary.mat-button-disabled, .app-dark .mat-fab.mat-accent.mat-button-disabled, .app-dark .mat-fab.mat-warn.mat-button-disabled, .app-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-flat-button.mat-primary, .app-dark .mat-raised-button.mat-primary, .app-dark .mat-fab.mat-primary, .app-dark .mat-mini-fab.mat-primary {
  background-color: #607d8b;
}
.app-dark .mat-flat-button.mat-accent, .app-dark .mat-raised-button.mat-accent, .app-dark .mat-fab.mat-accent, .app-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app-dark .mat-flat-button.mat-warn, .app-dark .mat-raised-button.mat-warn, .app-dark .mat-fab.mat-warn, .app-dark .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.app-dark .mat-flat-button.mat-primary.mat-button-disabled, .app-dark .mat-flat-button.mat-accent.mat-button-disabled, .app-dark .mat-flat-button.mat-warn.mat-button-disabled, .app-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-raised-button.mat-primary.mat-button-disabled, .app-dark .mat-raised-button.mat-accent.mat-button-disabled, .app-dark .mat-raised-button.mat-warn.mat-button-disabled, .app-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-fab.mat-primary.mat-button-disabled, .app-dark .mat-fab.mat-accent.mat-button-disabled, .app-dark .mat-fab.mat-warn.mat-button-disabled, .app-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-flat-button.mat-primary .mat-ripple-element, .app-dark .mat-raised-button.mat-primary .mat-ripple-element, .app-dark .mat-fab.mat-primary .mat-ripple-element, .app-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-flat-button.mat-accent .mat-ripple-element, .app-dark .mat-raised-button.mat-accent .mat-ripple-element, .app-dark .mat-fab.mat-accent .mat-ripple-element, .app-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-dark .mat-flat-button.mat-warn .mat-ripple-element, .app-dark .mat-raised-button.mat-warn .mat-ripple-element, .app-dark .mat-fab.mat-warn .mat-ripple-element, .app-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.app-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.app-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.app-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.app-dark .mat-card {
  background: #424242;
  color: white;
}
.app-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #607d8b;
}
.app-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}
.app-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #607d8b;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}
.app-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #607d8b;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-dark .mat-table {
  background: #424242;
}
.app-dark .mat-table thead, .app-dark .mat-table tbody, .app-dark .mat-table tfoot,
.app-dark mat-header-row, .app-dark mat-row, .app-dark mat-footer-row,
.app-dark [mat-header-row], .app-dark [mat-row], .app-dark [mat-footer-row],
.app-dark .mat-table-sticky {
  background: inherit;
}
.app-dark mat-row, .app-dark mat-header-row, .app-dark mat-footer-row,
.app-dark th.mat-header-cell, .app-dark td.mat-cell, .app-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-cell, .app-dark .mat-footer-cell {
  color: white;
}
.app-dark .mat-calendar-arrow {
  fill: white;
}
.app-dark .mat-datepicker-toggle,
.app-dark .mat-datepicker-content .mat-calendar-next-button,
.app-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-calendar-table-header,
.app-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-calendar-body-cell-content,
.app-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-calendar-body-in-range::before {
  background: rgba(96, 125, 139, 0.2);
}
.app-dark .mat-calendar-body-comparison-identical,
.app-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-calendar-body-comparison-bridge-start::before,
.app-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-calendar-body-comparison-bridge-end::before,
.app-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-calendar-body-selected {
  background-color: #607d8b;
  color: white;
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(96, 125, 139, 0.4);
}
.app-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(96, 125, 139, 0.3);
}
@media (hover: hover) {
  .app-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(96, 125, 139, 0.3);
  }
}
.app-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 215, 64, 0.3);
}
@media (hover: hover) {
  .app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 215, 64, 0.3);
  }
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
@media (hover: hover) {
  .app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 87, 34, 0.3);
  }
}
.app-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-datepicker-toggle-active {
  color: #607d8b;
}
.app-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}
.app-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app-dark .mat-expansion-panel-header-title {
  color: white;
}
.app-dark .mat-expansion-panel-header-description,
.app-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #607d8b;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}
.app-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app-dark .mat-form-field-ripple {
  background-color: white;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #607d8b;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #607d8b;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}
.app-dark .mat-error {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #607d8b;
}
.app-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app-dark .mat-icon.mat-primary {
  color: #607d8b;
}
.app-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app-dark .mat-icon.mat-warn {
  color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-input-element:disabled,
.app-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element {
  caret-color: #607d8b;
}
.app-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app-dark .mat-form-field.mat-warn .mat-input-element,
.app-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}
.app-dark .mat-list-base .mat-list-item {
  color: white;
}
.app-dark .mat-list-base .mat-list-option {
  color: white;
}
.app-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-list-option:hover, .app-dark .mat-list-option:focus,
.app-dark .mat-nav-list .mat-list-item:hover,
.app-dark .mat-nav-list .mat-list-item:focus,
.app-dark .mat-action-list .mat-list-item:hover,
.app-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-list-single-selected-option, .app-dark .mat-list-single-selected-option:hover, .app-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-menu-panel {
  background: #424242;
}
.app-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app-dark .mat-menu-item[disabled],
.app-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-menu-item .mat-icon-no-color,
.app-dark .mat-menu-submenu-icon {
  color: white;
}
.app-dark .mat-menu-item:hover:not([disabled]),
.app-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-paginator {
  background: #424242;
}
.app-dark .mat-paginator,
.app-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-paginator-decrement,
.app-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app-dark .mat-paginator-first,
.app-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app-dark .mat-icon-button[disabled] .mat-paginator-first,
.app-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-progress-bar-background {
  fill: #3c4347;
}
.app-dark .mat-progress-bar-buffer {
  background-color: #3c4347;
}
.app-dark .mat-progress-bar-fill::after {
  background-color: #607d8b;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645a34;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645a34;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643a2d;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643a2d;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}
.app-dark .mat-progress-spinner circle, .app-dark .mat-spinner circle {
  stroke: #607d8b;
}
.app-dark .mat-progress-spinner.mat-accent circle, .app-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app-dark .mat-progress-spinner.mat-warn circle, .app-dark .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}
.app-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #607d8b;
}
.app-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #607d8b;
}
.app-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.app-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.app-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-select-value {
  color: white;
}
.app-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-select-panel {
  background: #424242;
}
.app-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #607d8b;
}
.app-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.app-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.app-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #607d8b;
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(96, 125, 139, 0.54);
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #607d8b;
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.app-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app-dark .mat-slider.mat-primary .mat-slider-thumb,
.app-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #607d8b;
}
.app-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(96, 125, 139, 0.2);
}
.app-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app-dark .mat-slider.mat-accent .mat-slider-thumb,
.app-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app-dark .mat-slider.mat-warn .mat-slider-thumb,
.app-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.app-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}
.app-dark .mat-slider:hover .mat-slider-track-background,
.app-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app-dark .mat-step-header.cdk-keyboard-focused, .app-dark .mat-step-header.cdk-program-focused, .app-dark .mat-step-header:hover:not([aria-disabled]), .app-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app-dark .mat-step-header:hover {
    background: none;
  }
}
.app-dark .mat-step-header .mat-step-label,
.app-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app-dark .mat-step-header .mat-step-icon-selected,
.app-dark .mat-step-header .mat-step-icon-state-done,
.app-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #607d8b;
  color: white;
}
.app-dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.app-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}
.app-dark .mat-stepper-horizontal, .app-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-horizontal-stepper-header::before,
.app-dark .mat-horizontal-stepper-header::after,
.app-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app-dark .mat-tab-nav-bar,
.app-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app-dark .mat-tab-label, .app-dark .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.app-dark .mat-tab-group.mat-primary .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #607d8b;
}
.app-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-dark .mat-tab-group.mat-accent .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app-dark .mat-tab-group.mat-warn .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.app-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #607d8b;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app-dark .mat-toolbar.mat-primary {
  background: #607d8b;
  color: white;
}
.app-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.app-dark .mat-toolbar .mat-form-field-underline,
.app-dark .mat-toolbar .mat-form-field-ripple,
.app-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app-dark .mat-toolbar .mat-form-field-label,
.app-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app-dark .mat-toolbar .mat-select-value,
.app-dark .mat-toolbar .mat-select-arrow,
.app-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app-dark .mat-tree {
  background: #424242;
}
.app-dark .mat-tree-node,
.app-dark .mat-nested-tree-node {
  color: white;
}
.app-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-simple-snackbar-action {
  color: inherit;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: rgba(0, 0, 0, 0.582);
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.582);
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
/*@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps__rail-y {
  right: 0;
  left: auto !important;
}

.ps__thumb-y {
  right: 2px;
  left: auto !important;
}

[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 2px !important;
}

/* $scaffolding
 ------------------------------------------*/
html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
body.pace-done {
  background: #6b6b6b;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

html[dir=rtl], html[dir=ltr],
body[dir=rtl],
body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-card.settings-panel {
  position: fixed;
  bottom: 6px;
  right: 6px;
  width: 250px;
  z-index: 9;
}

.demo-checkbox .mat-checkbox,
.demo-checkbox .mat-checkbox-layout {
  width: 100%;
}
.demo-checkbox .mat-checkbox-layout .mat-checkbox-label {
  flex: 1;
  box-sizing: border-box;
  -webkit-box-flex: 1;
}

/* $typography
 ------------------------------------------*/
a {
  text-decoration: none;
  color: inherit;
}
a:focus, a:hover {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

strong,
b {
  font-weight: 700;
}

.strikethrough {
  text-decoration: line-through;
}

/* $sidebar-panel
 ------------------------------------------*/
mat-sidenav.sidebar-panel {
  position: absolute !important;
  overflow-x: hidden;
  width: 15rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
mat-sidenav.sidebar-panel .mat-drawer-focus-trap > .cdk-focus-trap-content {
  position: relative;
}
mat-sidenav.sidebar-panel > nav {
  min-width: 15rem;
}
mat-sidenav.sidebar-panel .mat-list-item .mat-list-item-content {
  display: block;
  height: auto;
  max-height: 48px;
  overflow: hidden;
  padding: 0;
  transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.sidebar-panel .mat-list-item {
  height: auto;
  background-color: transparent;
  transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.sidebar-panel .mat-list-item.open > .mat-list-item-content {
  max-height: 2000px;
  background: rgba(0, 0, 0, 0.04);
}
mat-sidenav.sidebar-panel .mat-nav-list a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
mat-sidenav.sidebar-panel .sub-menu {
  padding-top: 0;
  overflow: hidden;
  transition: 0.5s max-height cubic-bezier(0.35, 0, 0.25, 1);
  max-height: 0;
  transform: translateZ(0) !important;
}
mat-sidenav.sidebar-panel .sub-menu a {
  padding-left: 64px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu a {
  padding-left: 80px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu a {
  padding-left: 96px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu .sub-menu a {
  padding-left: 112px;
}
mat-sidenav.sidebar-panel .navigation mat-icon:not(.menu-caret) {
  margin-right: 24px;
}
mat-sidenav.sidebar-panel .navigation .menu-caret {
  display: inline-block;
  transition: transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  float: right;
  margin-left: 0.3125rem;
  text-align: center;
}
mat-sidenav.sidebar-panel .navigation .open > .mat-list-item-content > [appAccordionToggle] > .menu-caret {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
mat-sidenav.sidebar-panel .navigation .menu-badge {
  display: inline-block;
  height: 16px;
  min-width: 10px;
  line-height: 18px;
  text-align: center;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 700;
  padding: 0 4px;
}
mat-sidenav.sidebar-panel .navigation .open > .mat-list-item-content > .sub-menu {
  max-height: 2000px;
}

@media (min-width: 960px) {
  /* Collapsed sidebar */
  .collapsed-sidebar .mat-drawer-backdrop {
    display: none;
  }
  .collapsed-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
    margin-left: 80px !important;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel {
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) {
    width: 80px;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .sub-menu {
    display: none !important;
    visibility: hidden;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
.collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > .menu-caret {
    display: none;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    position: absolute;
    top: 10px;
    right: 16px;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > .material-icons {
    width: 80px !important;
    padding: 0;
    margin: 0;
  }

  /* Compact sidebar */
  .compact-sidebar {
    overflow-x: hidden;
  }
  .compact-sidebar > .mat-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .compact-sidebar > .app-inner {
    display: table;
    border-collapse: separate;
    min-height: calc(100vh - 64px);
    height: auto;
  }
}
@media (min-width: 960px) and (max-width: 599px) {
  .compact-sidebar > .app-inner {
    min-height: calc(100vh - 56px);
  }
}
@media (min-width: 960px) {
  .compact-sidebar > .app-inner .mat-drawer-content > .ps.ps--theme_default {
    overflow: visible !important;
  }
}
@media (min-width: 960px) {
  .compact-sidebar mat-sidenav.sidebar-panel ~ .mat-drawer-content {
    display: table-cell !important;
    vertical-align: top;
    height: auto;
    overflow: visible;
  }
}
@media (min-width: 960px) {
  .compact-sidebar.side-panel-closed mat-sidenav.sidebar-panel {
    position: absolute !important;
  }
}
@media (min-width: 960px) {
  .compact-sidebar.side-panel-opened mat-sidenav.sidebar-panel {
    position: relative !important;
  }
}
@media (min-width: 960px) {
  .compact-sidebar mat-sidenav.sidebar-panel {
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  }
  .compact-sidebar mat-sidenav.sidebar-panel > .ps__scrollbar-x-rail,
.compact-sidebar mat-sidenav.sidebar-panel > .ps__scrollbar-y-rail {
    display: none;
  }
}
@media (min-width: 960px) {
  .compact-sidebar mat-sidenav.sidebar-panel {
    width: 100px;
    overflow: visible !important;
    display: table-cell;
    vertical-align: top;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content {
    height: 60px;
    max-height: 60px;
    align-items: center;
    justify-content: center;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item {
    position: relative;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content {
    overflow: visible;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a {
    padding-right: 0;
    padding-left: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    box-sizing: content-box !important;
    flex-direction: column !important;
    height: 42px;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge):not(.menu-caret) {
    font-size: 12px;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    position: absolute;
    top: 10px;
    right: 16px;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > .material-icons {
    width: 100px !important;
    padding: 0;
    margin: 0;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item:hover > .mat-list-item-content > .sub-menu {
    display: block !important;
    visibility: visible;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .mat-list-item > .mat-list-item-content > a > .menu-caret,
.compact-sidebar mat-sidenav.sidebar-panel .mat-list-item > .mat-list-item-content > a > [fxflex] {
    display: none;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .sub-menu {
    display: none !important;
    visibility: hidden;
    background: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 0;
    left: 100%;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .sub-menu a {
    padding-left: 16px;
    height: 36px;
  }
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu a {
  padding-right: 64px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu a {
  padding-right: 80px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu a {
  padding-right: 96px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu .sub-menu a {
  padding-right: 112px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .navigation mat-icon:not(.menu-caret) {
  margin-left: 24px;
  margin-right: 0;
}
[dir=rtl] mat-sidenav.sidebar-panel .navigation .menu-caret {
  float: left;
  margin-right: 0.3125rem;
  margin-left: 0;
}

@media (min-width: 960px) {
  [dir=rtl].collapsed-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content,
[dir=rtl] .collapsed-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
    margin-left: 0 !important;
    margin-right: 80px !important;
  }
  [dir=rtl].collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span.menu-badge,
[dir=rtl] .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    right: auto;
    left: 16px;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    right: auto;
    left: 16px;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel .sub-menu,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel .sub-menu {
    left: auto;
    right: 100%;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel .sub-menu a,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel .sub-menu a {
    padding-left: 16px;
    padding-right: 16px;
  }

  .app-dark.compact-sidebar mat-sidenav.sidebar-panel .sub-menu,
.app-dark .compact-sidebar mat-sidenav.sidebar-panel .sub-menu {
    background: #424242;
  }
}
/* $header
 ------------------------------------------*/
mat-toolbar .toolbar-avatar {
  width: 40px;
  height: 40px;
  line-height: 24px;
}
mat-toolbar .toolbar-avatar img {
  width: 40px;
  border-radius: 50%;
}
mat-toolbar .notification-label {
  position: absolute;
  top: 0;
  left: 54%;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background-color: #f44336;
  border: 2px solid #f44336;
  color: white;
  text-align: center;
}
mat-toolbar.main-header {
  padding: 0 8px;
  position: relative;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  z-index: 9;
  width: 100% !important;
}
mat-toolbar.main-header .branding {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto 0;
  line-height: 50px;
  padding: 0 64px 0 16px;
}
mat-toolbar.main-header .logo {
  background-image: url(/assets/images/logo.svg);
  width: 109px;
  height: 25px;
  -webkit-background-size: 109px 25px;
  background-size: 109px 25px;
  margin: auto;
}
mat-toolbar.main-header .search-bar .search-form {
  background: white;
  position: relative;
  border-radius: 2px;
  margin-right: 1rem;
  display: block;
  max-width: 800px;
}
mat-toolbar.main-header .search-bar .search-form input {
  font-size: 1rem;
  padding: 0.95rem 0.75rem;
  z-index: 2;
  cursor: text;
  text-indent: 30px;
  border: none;
  background: transparent;
  width: 100%;
  outline: 0;
}
mat-toolbar.main-header .search-bar .search-form .material-icons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 599px) and (orientation: portrait) {
  mat-toolbar.main-header .branding {
    padding: 0 16px 0 16px;
  }
  mat-toolbar.main-header .logo {
    width: 87px;
    height: 20px;
    -webkit-background-size: 87px 20px;
    background-size: 87px 20px;
  }
}
[dir=rtl] .main-header .branding {
  padding: 0 16px 0 64px;
}
[dir=rtl] .main-header .search-bar .search-form .material-icons {
  left: auto;
  right: 10px;
}

/* $main-panel
 ------------------------------------------*/
.app-inner {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

/* Boxed admin-layout*/
.app.boxed {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .app.boxed {
    max-width: 46.88rem;
  }
}
@media (min-width: 62rem) {
  .app.boxed {
    max-width: 60.63rem;
  }
}
@media (min-width: 75rem) {
  .app.boxed {
    max-width: 73.13rem;
  }
}
.mat-drawer-content {
  height: 100%;
}
.mat-drawer-content > .ps {
  position: relative;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.3333333333rem;
}
.mat-drawer-content > .main-content {
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mat-drawer-content > .main-content > .content-view {
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.app-dark .mat-drawer-content {
  background-color: #353535;
}

@media (min-width: 960px) {
  .side-panel-opened[dir=rtl] .mat-drawer-content {
    margin-left: 0 !important;
    margin-right: 15rem !important;
  }
}
/* $chat-panel
 ------------------------------------------*/
.chat-panel {
  max-width: 320px;
  width: 100%;
  overflow: hidden;
}
.chat-panel .mat-drawer-inner-container {
  overflow: hidden;
}
.chat-panel mat-tab-group,
.chat-panel .mat-tab-body-wrapper,
.chat-panel mat-tab-body,
.chat-panel .mat-tab-body-content {
  height: 100%;
}
.chat-panel .scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

/* $error-pages
 ------------------------------------------*/
.session {
  position: relative;
  z-index: 999;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.session-content {
  padding: 40px 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}

.session-wrapper {
  -webkit-box-flex: none;
  -webkit-flex: none;
  -moz-box-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: -75px auto 0 !important;
}

.lockscreen-wrapper {
  -webkit-box-flex: none;
  -webkit-flex: none;
  -moz-box-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.error-title {
  font-size: 150px;
  line-height: 1.2;
  font-weight: 900;
  display: inline-table;
  position: relative;
  background: #3f51b5;
  color: #fff;
  padding: 0 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 1rem;
}
.error-title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #3f51b5;
  border-width: 8px;
  margin-left: -8px;
}

.error-subtitle {
  font-size: 32px;
  font-weight: 300;
}

.media-grid {
  text-align: center;
  overflow: hidden;
}
.media-grid figure {
  position: relative;
  text-align: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.38);
  text-align: left;
  border-radius: 2px 2px 0 0;
  text-align: center;
}
.media-grid figure img {
  position: relative;
  display: block;
  max-width: 100%;
  border-radius: 2px 2px 0 0;
}
.media-grid.compact {
  border-radius: 2px 0 0 2px;
}
.media-grid.compact figure {
  border-radius: 2px 0 0 2px;
}
.media-grid.compact figure img {
  border-radius: 2px 0 0 2px;
}
.media-grid .product-badge {
  color: #fff;
  text-align: center;
  position: absolute;
  border-top: 30px solid #f44336;
  border-bottom: 30px solid transparent;
  border-right: 30px solid transparent;
  border-left: 30px solid #f44336;
  top: 0;
  left: 0;
  z-index: 100;
}
.media-grid .product-badge span {
  position: absolute;
  transform: rotate(-45deg);
  top: -18px;
  left: -25px;
  white-space: nowrap;
}
.media-grid figure figcaption {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
}
.media-grid figure figcaption::before {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.38);
  content: "";
  transition: transform 300ms;
}
.media-grid figure figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 45px 0 0;
  transform: translate3d(45px, 0, 0);
}
.media-grid figure figcaption,
.media-grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}
.media-grid figure p {
  margin: 0;
}
.media-grid figure p {
  float: right;
  clear: both;
  text-align: center;
  text-transform: none;
  font-size: 1rem;
  width: 45px;
  padding-top: 1rem;
}
.media-grid figure img,
.media-grid figure p a {
  transition: opacity 300ms, transform 300ms;
}
.media-grid figure img {
  opacity: 0.85;
}
.media-grid figure p a {
  display: block;
  margin-bottom: 1rem;
  color: #fff;
  opacity: 0;
  transform: translate3d(90px, 0, 0);
}
.media-grid figure:hover figcaption::before {
  transform: translate3d(0, 0, 0);
}
.media-grid figure:hover p a {
  transform: translate3d(0, 0, 0);
}
.media-grid figure:hover p a {
  opacity: 1;
}
.media-grid figure:hover p a:first-child {
  transition-delay: 0.025s;
}
.media-grid figure:hover p a:nth-child(2) {
  transition-delay: 0.05s;
}
.media-grid figure:hover p a:nth-child(3) {
  transition-delay: 0.075s;
}
.media-grid figure:hover p a:nth-child(4) {
  transition-delay: 0.1s;
}

[mat-raised-button],
[mat-fab],
[mat-mini-fab],
[mat-button],
[mat-icon-button] {
  border-radius: 2px;
  font-weight: 400;
}
[mat-raised-button][mat-button-sm],
[mat-fab][mat-button-sm],
[mat-mini-fab][mat-button-sm],
[mat-button][mat-button-sm],
[mat-icon-button][mat-button-sm] {
  font-size: 13px;
  line-height: 30px;
  padding: 0 8px;
}

[mat-icon-button][mat-button-sm] {
  padding: 0;
  width: 30px;
  height: 30px;
}
[mat-icon-button][mat-button-sm] mat-icon {
  font-size: 20px;
  line-height: 20px;
}

[mat-fab].mat-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 1rem;
  left: auto;
  position: fixed;
}

[mat-fab].mat-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: -25px;
  left: 20px;
  position: fixed;
}

[mat-fab].mat-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: fixed;
}

[mat-fab].mat-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: fixed;
}

/* $cards
 ------------------------------------------*/
body .mat-card {
  margin: 0.3333333333rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
body .mat-card > :first-child {
  border-radius: 2px 2px 0 0;
}
body .mat-card > :last-child {
  border-radius: 0 0 2px 2px;
}
body .mat-card .mat-card-title {
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
}
body .mat-card .mat-card-subtitle {
  line-height: 1;
  font-size: 13px;
}
body .mat-card .content-full,
body .mat-card > hr {
  width: calc(100% + 48px);
  margin: 0 -24px 16px -24px;
}
body .mat-card [mat-fab-card-float] {
  top: -36px;
  position: absolute;
  right: 8px;
}
body .mat-card [mat-card-float-icon] {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
}
body .mat-card [mat-card-float-icon] .material-icons {
  font-size: 40px;
  opacity: 0.2;
  transform: rotate(-5deg);
}
body .mat-card [mat-card-widget] {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
body .mat-card [mat-card-widget] [mat-card-widget-title],
body .mat-card [mat-card-widget] p {
  margin: 0;
  padding: 0;
  line-height: 1.1 !important;
}
body .mat-card.card-widget {
  padding: 20px;
}
body .mat-card.card-widget .card-widget-content {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: -0.5rem 0 1rem 0;
}

body [dir=rtl] .mat-card [mat-fab-card-float] {
  right: auto;
  left: 8px;
}
body [dir=rtl] .mat-card [mat-card-float-icon] {
  right: auto;
  left: 15px;
}

.mat-dialog-container {
  background: white;
  border-radius: 2px;
}

/* $listgroup
 ------------------------------------------*/
mat-list mat-list-item,
mat-list a[mat-list-item],
mat-nav-list mat-list-item,
mat-nav-list a[mat-list-item] {
  color: rgba(0, 0, 0, 0.87);
}

mat-list-item mat-icon[mat-list-avatar],
.mat-list-item mat-icon[mat-list-avatar] {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}

mat-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

mat-list mat-list-item [mat-list-avatar],
mat-list a[mat-list-item] [mat-list-avatar],
mat-nav-list mat-list-item [mat-list-avatar],
mat-nav-list a[mat-list-item] [mat-list-avatar] {
  min-width: 40px;
}

mat-list-item.active > .mat-list-item-content {
  background: rgba(0, 0, 0, 0.04);
}

mat-list mat-list-item .mat-list-item-content,
mat-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content {
  font-size: 0.875rem !important;
}

mat-card > mat-list > mat-list-item > .mat-list-item-content {
  padding: 0 !important;
}

body .mat-select-panel {
  background-color: white;
}
body .mat-select-trigger,
body .mat-option {
  font-size: 0.875rem;
}
body .app-dark .mat-select-panel {
  background-color: #424242;
}

.mat-menu {
  border-radius: 2px;
}

[mat-menu-item] {
  font-size: 0.875rem !important;
}

.mat-tab-label {
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  display: flex;
  justify-content: center;
}

.app-dark .mat-tab-label {
  color: white;
}

.mat-red {
  background-color: #f44336 !important;
  color: white !important;
}

.mat-red-50 {
  background-color: #ffebee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-100 {
  background-color: #ffcdd2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-200 {
  background-color: #ef9a9a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-300 {
  background-color: #e57373 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-400 {
  background-color: #ef5350 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-500 {
  background-color: #f44336 !important;
  color: white !important;
}

.mat-red-600 {
  background-color: #e53935 !important;
  color: white !important;
}

.mat-red-700 {
  background-color: #d32f2f !important;
  color: white !important;
}

.mat-red-800 {
  background-color: #c62828 !important;
  color: white !important;
}

.mat-red-900 {
  background-color: #b71c1c !important;
  color: white !important;
}

.mat-red-A100 {
  background-color: #ff8a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-A200 {
  background-color: #ff5252 !important;
  color: white !important;
}

.mat-red-A400 {
  background-color: #ff1744 !important;
  color: white !important;
}

.mat-red-A700 {
  background-color: #d50000 !important;
  color: white !important;
}

.mat-pink {
  background-color: #e91e63 !important;
  color: white !important;
}

.mat-pink-50 {
  background-color: #fce4ec !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-100 {
  background-color: #f8bbd0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-200 {
  background-color: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-300 {
  background-color: #f06292 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-400 {
  background-color: #ec407a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-500 {
  background-color: #e91e63 !important;
  color: white !important;
}

.mat-pink-600 {
  background-color: #d81b60 !important;
  color: white !important;
}

.mat-pink-700 {
  background-color: #c2185b !important;
  color: white !important;
}

.mat-pink-800 {
  background-color: #ad1457 !important;
  color: white !important;
}

.mat-pink-900 {
  background-color: #880e4f !important;
  color: white !important;
}

.mat-pink-A100 {
  background-color: #ff80ab !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-A200 {
  background-color: #ff4081 !important;
  color: white !important;
}

.mat-pink-A400 {
  background-color: #f50057 !important;
  color: white !important;
}

.mat-pink-A700 {
  background-color: #c51162 !important;
  color: white !important;
}

.mat-purple {
  background-color: #9c27b0 !important;
  color: white !important;
}

.mat-purple-50 {
  background-color: #f3e5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-100 {
  background-color: #e1bee7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-200 {
  background-color: #ce93d8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-300 {
  background-color: #ba68c8 !important;
  color: white !important;
}

.mat-purple-400 {
  background-color: #ab47bc !important;
  color: white !important;
}

.mat-purple-500 {
  background-color: #9c27b0 !important;
  color: white !important;
}

.mat-purple-600 {
  background-color: #8e24aa !important;
  color: white !important;
}

.mat-purple-700 {
  background-color: #7b1fa2 !important;
  color: white !important;
}

.mat-purple-800 {
  background-color: #6a1b9a !important;
  color: white !important;
}

.mat-purple-900 {
  background-color: #4a148c !important;
  color: white !important;
}

.mat-purple-A100 {
  background-color: #ea80fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-A200 {
  background-color: #e040fb !important;
  color: white !important;
}

.mat-purple-A400 {
  background-color: #d500f9 !important;
  color: white !important;
}

.mat-purple-A700 {
  background-color: #aa00ff !important;
  color: white !important;
}

.mat-deep-purple {
  background-color: #673ab7 !important;
  color: white !important;
}

.mat-deep-purple-50 {
  background-color: #ede7f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-100 {
  background-color: #d1c4e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-200 {
  background-color: #b39ddb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-300 {
  background-color: #9575cd !important;
  color: white !important;
}

.mat-deep-purple-400 {
  background-color: #7e57c2 !important;
  color: white !important;
}

.mat-deep-purple-500 {
  background-color: #673ab7 !important;
  color: white !important;
}

.mat-deep-purple-600 {
  background-color: #5e35b1 !important;
  color: white !important;
}

.mat-deep-purple-700 {
  background-color: #512da8 !important;
  color: white !important;
}

.mat-deep-purple-800 {
  background-color: #4527a0 !important;
  color: white !important;
}

.mat-deep-purple-900 {
  background-color: #311b92 !important;
  color: white !important;
}

.mat-deep-purple-A100 {
  background-color: #b388ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-A200 {
  background-color: #7c4dff !important;
  color: white !important;
}

.mat-deep-purple-A400 {
  background-color: #651fff !important;
  color: white !important;
}

.mat-deep-purple-A700 {
  background-color: #6200ea !important;
  color: white !important;
}

.mat-indigo {
  background-color: #3f51b5 !important;
  color: white !important;
}

.mat-indigo-50 {
  background-color: #e8eaf6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-100 {
  background-color: #c5cae9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-200 {
  background-color: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-300 {
  background-color: #7986cb !important;
  color: white !important;
}

.mat-indigo-400 {
  background-color: #5c6bc0 !important;
  color: white !important;
}

.mat-indigo-500 {
  background-color: #3f51b5 !important;
  color: white !important;
}

.mat-indigo-600 {
  background-color: #3949ab !important;
  color: white !important;
}

.mat-indigo-700 {
  background-color: #303f9f !important;
  color: white !important;
}

.mat-indigo-800 {
  background-color: #283593 !important;
  color: white !important;
}

.mat-indigo-900 {
  background-color: #1a237e !important;
  color: white !important;
}

.mat-indigo-A100 {
  background-color: #8c9eff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-A200 {
  background-color: #536dfe !important;
  color: white !important;
}

.mat-indigo-A400 {
  background-color: #3d5afe !important;
  color: white !important;
}

.mat-indigo-A700 {
  background-color: #304ffe !important;
  color: white !important;
}

.mat-blue {
  background-color: #2196f3 !important;
  color: white !important;
}

.mat-blue-50 {
  background-color: #e3f2fd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-100 {
  background-color: #bbdefb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-200 {
  background-color: #90caf9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-300 {
  background-color: #64b5f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-400 {
  background-color: #42a5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-500 {
  background-color: #2196f3 !important;
  color: white !important;
}

.mat-blue-600 {
  background-color: #1e88e5 !important;
  color: white !important;
}

.mat-blue-700 {
  background-color: #1976d2 !important;
  color: white !important;
}

.mat-blue-800 {
  background-color: #1565c0 !important;
  color: white !important;
}

.mat-blue-900 {
  background-color: #0d47a1 !important;
  color: white !important;
}

.mat-blue-A100 {
  background-color: #82b1ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-A200 {
  background-color: #448aff !important;
  color: white !important;
}

.mat-blue-A400 {
  background-color: #2979ff !important;
  color: white !important;
}

.mat-blue-A700 {
  background-color: #2962ff !important;
  color: white !important;
}

.mat-light-blue {
  background-color: #03a9f4 !important;
  color: white !important;
}

.mat-light-blue-50 {
  background-color: #e1f5fe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-100 {
  background-color: #b3e5fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-200 {
  background-color: #81d4fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-300 {
  background-color: #4fc3f7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-400 {
  background-color: #29b6f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-500 {
  background-color: #03a9f4 !important;
  color: white !important;
}

.mat-light-blue-600 {
  background-color: #039be5 !important;
  color: white !important;
}

.mat-light-blue-700 {
  background-color: #0288d1 !important;
  color: white !important;
}

.mat-light-blue-800 {
  background-color: #0277bd !important;
  color: white !important;
}

.mat-light-blue-900 {
  background-color: #01579b !important;
  color: white !important;
}

.mat-light-blue-A100 {
  background-color: #80d8ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-A200 {
  background-color: #40c4ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-A400 {
  background-color: #00b0ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-A700 {
  background-color: #0091ea !important;
  color: white !important;
}

.mat-cyan {
  background-color: #00bcd4 !important;
  color: white !important;
}

.mat-cyan-50 {
  background-color: #e0f7fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-100 {
  background-color: #b2ebf2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-200 {
  background-color: #80deea !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-300 {
  background-color: #4dd0e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-400 {
  background-color: #26c6da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-500 {
  background-color: #00bcd4 !important;
  color: white !important;
}

.mat-cyan-600 {
  background-color: #00acc1 !important;
  color: white !important;
}

.mat-cyan-700 {
  background-color: #0097a7 !important;
  color: white !important;
}

.mat-cyan-800 {
  background-color: #00838f !important;
  color: white !important;
}

.mat-cyan-900 {
  background-color: #006064 !important;
  color: white !important;
}

.mat-cyan-A100 {
  background-color: #84ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-A200 {
  background-color: #18ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-A400 {
  background-color: #00e5ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-A700 {
  background-color: #00b8d4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal {
  background-color: #009688 !important;
  color: white !important;
}

.mat-teal-50 {
  background-color: #e0f2f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-100 {
  background-color: #b2dfdb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-200 {
  background-color: #80cbc4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-300 {
  background-color: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-400 {
  background-color: #26a69a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-500 {
  background-color: #009688 !important;
  color: white !important;
}

.mat-teal-600 {
  background-color: #00897b !important;
  color: white !important;
}

.mat-teal-700 {
  background-color: #00796b !important;
  color: white !important;
}

.mat-teal-800 {
  background-color: #00695c !important;
  color: white !important;
}

.mat-teal-900 {
  background-color: #004d40 !important;
  color: white !important;
}

.mat-teal-A100 {
  background-color: #a7ffeb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-A200 {
  background-color: #64ffda !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-A400 {
  background-color: #1de9b6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-A700 {
  background-color: #00bfa5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-50 {
  background-color: #e8f5e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-100 {
  background-color: #c8e6c9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-200 {
  background-color: #a5d6a7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-300 {
  background-color: #81c784 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-400 {
  background-color: #66bb6a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-500 {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-600 {
  background-color: #43a047 !important;
  color: white !important;
}

.mat-green-700 {
  background-color: #388e3c !important;
  color: white !important;
}

.mat-green-800 {
  background-color: #2e7d32 !important;
  color: white !important;
}

.mat-green-900 {
  background-color: #1b5e20 !important;
  color: white !important;
}

.mat-green-A100 {
  background-color: #b9f6ca !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-A200 {
  background-color: #69f0ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-A400 {
  background-color: #00e676 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-A700 {
  background-color: #00c853 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-50 {
  background-color: #f1f8e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-100 {
  background-color: #dcedc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-200 {
  background-color: #c5e1a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-300 {
  background-color: #aed581 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-400 {
  background-color: #9ccc65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-500 {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-600 {
  background-color: #7cb342 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-700 {
  background-color: #689f38 !important;
  color: white !important;
}

.mat-light-green-800 {
  background-color: #558b2f !important;
  color: white !important;
}

.mat-light-green-900 {
  background-color: #33691e !important;
  color: white !important;
}

.mat-light-green-A100 {
  background-color: #ccff90 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-A200 {
  background-color: #b2ff59 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-A400 {
  background-color: #76ff03 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-A700 {
  background-color: #64dd17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-50 {
  background-color: #f9fbe7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-100 {
  background-color: #f0f4c3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-200 {
  background-color: #e6ee9c !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-300 {
  background-color: #dce775 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-400 {
  background-color: #d4e157 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-500 {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-600 {
  background-color: #c0ca33 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-700 {
  background-color: #afb42b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-800 {
  background-color: #9e9d24 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-900 {
  background-color: #827717 !important;
  color: white !important;
}

.mat-lime-A100 {
  background-color: #f4ff81 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-A200 {
  background-color: #eeff41 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-A400 {
  background-color: #c6ff00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-A700 {
  background-color: #aeea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-50 {
  background-color: #fffde7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-100 {
  background-color: #fff9c4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-200 {
  background-color: #fff59d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-300 {
  background-color: #fff176 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-400 {
  background-color: #ffee58 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-500 {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-600 {
  background-color: #fdd835 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-700 {
  background-color: #fbc02d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-800 {
  background-color: #f9a825 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-900 {
  background-color: #f57f17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A100 {
  background-color: #ffff8d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A200 {
  background-color: yellow !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A400 {
  background-color: #ffea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A700 {
  background-color: #ffd600 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-50 {
  background-color: #fff8e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-100 {
  background-color: #ffecb3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-200 {
  background-color: #ffe082 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-300 {
  background-color: #ffd54f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-400 {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-500 {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-600 {
  background-color: #ffb300 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-700 {
  background-color: #ffa000 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-800 {
  background-color: #ff8f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-900 {
  background-color: #ff6f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A100 {
  background-color: #ffe57f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A200 {
  background-color: #ffd740 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A400 {
  background-color: #ffc400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A700 {
  background-color: #ffab00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-50 {
  background-color: #fff3e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-100 {
  background-color: #ffe0b2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-200 {
  background-color: #ffcc80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-300 {
  background-color: #ffb74d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-400 {
  background-color: #ffa726 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-500 {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-600 {
  background-color: #fb8c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-700 {
  background-color: #f57c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-800 {
  background-color: #ef6c00 !important;
  color: white !important;
}

.mat-orange-900 {
  background-color: #e65100 !important;
  color: white !important;
}

.mat-orange-A100 {
  background-color: #ffd180 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-A200 {
  background-color: #ffab40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-A400 {
  background-color: #ff9100 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-A700 {
  background-color: #ff6d00 !important;
  color: black !important;
}

.mat-deep-orange {
  background-color: #ff5722 !important;
  color: white !important;
}

.mat-deep-orange-50 {
  background-color: #fbe9e7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-100 {
  background-color: #ffccbc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-200 {
  background-color: #ffab91 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-300 {
  background-color: #ff8a65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-400 {
  background-color: #ff7043 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-500 {
  background-color: #ff5722 !important;
  color: white !important;
}

.mat-deep-orange-600 {
  background-color: #f4511e !important;
  color: white !important;
}

.mat-deep-orange-700 {
  background-color: #e64a19 !important;
  color: white !important;
}

.mat-deep-orange-800 {
  background-color: #d84315 !important;
  color: white !important;
}

.mat-deep-orange-900 {
  background-color: #bf360c !important;
  color: white !important;
}

.mat-deep-orange-A100 {
  background-color: #ff9e80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-A200 {
  background-color: #ff6e40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-A400 {
  background-color: #ff3d00 !important;
  color: white !important;
}

.mat-deep-orange-A700 {
  background-color: #dd2c00 !important;
  color: white !important;
}

.mat-brown {
  background-color: #795548 !important;
  color: white !important;
}

.mat-brown-50 {
  background-color: #efebe9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-300 {
  background-color: #a1887f !important;
  color: white !important;
}

.mat-brown-400 {
  background-color: #8d6e63 !important;
  color: white !important;
}

.mat-brown-500 {
  background-color: #795548 !important;
  color: white !important;
}

.mat-brown-600 {
  background-color: #6d4c41 !important;
  color: white !important;
}

.mat-brown-700 {
  background-color: #5d4037 !important;
  color: white !important;
}

.mat-brown-800 {
  background-color: #4e342e !important;
  color: white !important;
}

.mat-brown-900 {
  background-color: #3e2723 !important;
  color: white !important;
}

.mat-brown-A100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-A200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-A400 {
  background-color: #8d6e63 !important;
  color: white !important;
}

.mat-brown-A700 {
  background-color: #5d4037 !important;
  color: white !important;
}

.mat-grey {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-50 {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-100 {
  background-color: whitesmoke !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-300 {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-500 {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-600 {
  background-color: #757575 !important;
  color: white !important;
}

.mat-grey-700 {
  background-color: #616161 !important;
  color: white !important;
}

.mat-grey-800 {
  background-color: #424242 !important;
  color: white !important;
}

.mat-grey-900 {
  background-color: #212121 !important;
  color: white !important;
}

.mat-grey-A100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-A200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-A400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-A700 {
  background-color: #616161 !important;
  color: white !important;
}

.mat-blue-grey {
  background-color: #607d8b !important;
  color: white !important;
}

.mat-blue-grey-50 {
  background-color: #eceff1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-300 {
  background-color: #90a4ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-400 {
  background-color: #78909c !important;
  color: white !important;
}

.mat-blue-grey-500 {
  background-color: #607d8b !important;
  color: white !important;
}

.mat-blue-grey-600 {
  background-color: #546e7a !important;
  color: white !important;
}

.mat-blue-grey-700 {
  background-color: #455a64 !important;
  color: white !important;
}

.mat-blue-grey-800 {
  background-color: #37474f !important;
  color: white !important;
}

.mat-blue-grey-900 {
  background-color: #263238 !important;
  color: white !important;
}

.mat-blue-grey-A100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-A200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-A400 {
  background-color: #78909c !important;
  color: white !important;
}

.mat-blue-grey-A700 {
  background-color: #455a64 !important;
  color: white !important;
}

.radius-none {
  border-radius: 0;
}

.radius-round {
  border-radius: 2px;
}

.radius-circle {
  border-radius: 50%;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.block {
  display: block;
}

.button-block {
  display: block;
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: 0 !important;
}

.background-none {
  background-color: transparent !important;
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.ma-0 {
  margin: 0 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ma-xs {
  margin: 0.5555555556rem 0.5555555556rem !important;
}

.mt-xs {
  margin-top: 0.5555555556rem !important;
}

.mr-xs {
  margin-right: 0.5555555556rem !important;
}

.mb-xs {
  margin-bottom: 0.5555555556rem !important;
}

.ml-xs {
  margin-left: 0.5555555556rem !important;
}

.mx-xs {
  margin-right: 0.5555555556rem !important;
  margin-left: 0.5555555556rem !important;
}

.my-xs {
  margin-top: 0.5555555556rem !important;
  margin-bottom: 0.5555555556rem !important;
}

.ma-1 {
  margin: 1rem 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ma-2 {
  margin: 1.5rem 1.5rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.mx-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.ma-3 {
  margin: 3rem 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.pa-0 {
  padding: 0 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pa-xs {
  padding: 0.5555555556rem 0.5555555556rem !important;
}

.pt-xs {
  padding-top: 0.5555555556rem !important;
}

.pr-xs {
  padding-right: 0.5555555556rem !important;
}

.pb-xs {
  padding-bottom: 0.5555555556rem !important;
}

.pl-xs {
  padding-left: 0.5555555556rem !important;
}

.px-xs {
  padding-right: 0.5555555556rem !important;
  padding-left: 0.5555555556rem !important;
}

.py-xs {
  padding-top: 0.5555555556rem !important;
  padding-bottom: 0.5555555556rem !important;
}

.pa-1 {
  padding: 1rem 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pa-2 {
  padding: 1.5rem 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.px-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pa-3 {
  padding: 3rem 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.px-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

@media (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-xs {
  font-size: 50% !important;
}

.text-sm {
  font-size: 70% !important;
}

.text-md {
  font-size: 80% !important;
}

.text-df {
  font-size: 13px !important;
}

.text-lg {
  font-size: 110% !important;
}

.text-xl {
  font-size: 120% !important;
}

.text-xxl {
  font-size: 60px !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.mat-text-disabled {
  color: #546e7a;
}

.mat-text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

a.mat-text-muted:focus, a.mat-text-muted:hover {
  color: rgba(0, 0, 0, 0.54);
}

.mat-text-default {
  color: black !important;
}

a.mat-text-default:focus, a.mat-text-default:hover {
  color: black;
}

.mat-text-primary {
  color: #3f51b5 !important;
}

a.mat-text-primary:focus, a.mat-text-primary:hover {
  color: #32408f;
}

.mat-text-warn {
  color: #f44336 !important;
}

a.mat-text-warn:focus, a.mat-text-warn:hover {
  color: #ea1c0d;
}

.mat-text-accent {
  color: #009688 !important;
}

a.mat-text-accent:focus, a.mat-text-accent:hover {
  color: #00635a;
}

.mat-text-attention {
  color: #f9a825;
}

.app-dark .mat-text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.app-dark a.mat-text-muted:focus, .app-dark a.mat-text-muted:hover {
  color: rgba(230, 230, 230, 0.7);
}
.app-dark .mat-text-default {
  color: white !important;
}
.app-dark a.mat-text-default:focus, .app-dark a.mat-text-default:hover {
  color: #e6e6e6;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}